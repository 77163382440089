import * as actionTypes from '../actions/actionTypes'
import { updateObject } from '../../shared/utility'

const initialState = {
  reports: {},
  file: '',
  loading: false,
  changing: 0,
  error: {},
}

const downloadAssetsStart = (state, action) => {
  return updateObject(state, {
    loading: true
  })
}

const downloadAssetsSuccess = (state, action) => {
  return updateObject(state, {
    loading: false,
    changing: state.changing+1,
    file: action.file
  })
}

const downloadAssetsFail = (state, action) => {
  return updateObject(state, {
    loading: false,
    error: action.error
  })
}

const downloadQRAssetsStart = (state, action) => {
  return updateObject(state, {
    loading: true
  })
}

const downloadQRAssetsSuccess = (state, action) => {
  return updateObject(state, {
    loading: false,
    changing: state.changing+1,
    file: action.file
  })
}

const downloadQRAssetsFail = (state, action) => {
  return updateObject(state, {
    loading: false,
    error: action.error
  })
}

const fetchListReportsStart = (state, action) => {
  return updateObject(state, {
    loading: true
  })
}

const fetchListReportsSuccess = (state, action) => {
  return updateObject(state, {
    loading: false,
    reports: action.reports
  })
}

const fetchListReportsFail = (state, action) => {
  return updateObject(state, {
    loading: false,
    error: action.error
  })
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.DOWNLOAD_LAPORAN_ASSETS_START: return downloadAssetsStart(state, action)
    case actionTypes.DOWNLOAD_LAPORAN_ASSETS_SUCCESS: return downloadAssetsSuccess(state, action)
    case actionTypes.DOWNLOAD_LAPORAN_ASSETS_FAIL: return downloadAssetsFail(state, action)
    case actionTypes.DOWNLOAD_LAPORAN_QR_ASSETS_START: return downloadQRAssetsStart(state, action)
    case actionTypes.DOWNLOAD_LAPORAN_QR_ASSETS_SUCCESS: return downloadQRAssetsSuccess(state, action)
    case actionTypes.DOWNLOAD_LAPORAN_QR_ASSETS_FAIL: return downloadQRAssetsFail(state, action)
    case actionTypes.FETCH_LIST_REPORTS_START: return fetchListReportsStart(state, action)
    case actionTypes.FETCH_LIST_REPORTS_SUCCESS: return fetchListReportsSuccess(state, action)
    case actionTypes.FETCH_LIST_REPORTS_FAIL: return fetchListReportsFail(state, action)
    default: return state
  }
}

export default reducer