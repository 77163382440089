import React, {Fragment, useEffect, useState} from "react";
import * as actions from "../../../store/actions";
import {connect} from "react-redux";
import {useHistory} from "react-router-dom";
import {Fab, Grid, makeStyles, Typography} from "@material-ui/core";
import Modal from "../../../components/UI/Modal/ScrollDialog/ScrollDialog";
import {AddCircle} from "@material-ui/icons";
import BrandList from "../../../components/View/Master/Brand/BrandList/BrandList";
// import EditBrand from "../../../components/View/Master/Division/EditBrand/EditBrand";
import AddBrand from "../../../components/View/Master/Brand/AddBrand/AddBrand";
import EditBrand from "../../../components/View/Master/Brand/EditBrand/EditBrand";

const useStyles = makeStyles(theme => ({
  row: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  contentSpace: {
    padding: '10px 32px'
    // backgroundColor: '#EEEEEE'
  },
  fab: {
    position: 'fixed',
    bottom: theme.spacing(4),
    right: theme.spacing(2)
  },
  sideRight: {
    textAlign: 'right'
  },
  btnAdd: {
    backgroundColor: '#FF9300',
    '&:hover': {
      backgroundColor: '#FF9300'
    },
    color: '#FFFFFF',
    width: '100%',
    justifyContent: 'left'
  }
  ,
  btnAddJenis: {
    backgroundColor: '#FF9300',
    '&:hover': {
      backgroundColor: '#FF9300'
    },
    color: '#FFFFFF',
    // width: '100%',
    // justifyContent: 'cen'
  },
  extendedIcon: {
    marginRight: theme.spacing(1),

  },
}))

const Brand = props => {

  const {onSetAuthRedirect, authRedirectPath, changing} = props;
  const classes = useStyles();
  const path = useHistory().location.pathname;

  useEffect(() => {
    if(authRedirectPath !== path){
      onSetAuthRedirect(path)
    }
    closedModalDialog()
  }, [onSetAuthRedirect, authRedirectPath, path, changing]);

  const [modalState, setModalState] = useState({
    maxWidth: 'sm',
    title: '',
    open: false,
  })

  const [form, setForm] = useState('');

  const addBrand = () => {
    setModalState({
      maxWidth: 'xs',
      title: 'Tambah Merek',
      open: true,
    });
    setForm(<AddBrand closedModalDialog={() => closedModalDialog()}/>);
  }

  const editBrand = (brand) => {
    setModalState({
      maxWidth: 'xs',
      title: 'Edit Merek',
      open: true,
    });
    setForm(<EditBrand brandData={brand} closedModalDialog={() => closedModalDialog()}/>);
  }

  const closedModalDialog = () => {
    setModalState({
      maxWidth: 'sm',
      title: '',
      open: false,
    });
    setForm('');
  }

  return (
    <Fragment>
      <div className={classes.contentSpace}>
        {/*{loading}*/}

        <Modal
          maxWidth={modalState.maxWidth}
          open={modalState.open}
          title={modalState.title}
          onCloseModal={closedModalDialog}
          contentModal={form}
        />

        <div className={classes.row}>
          <Grid container >
            <Grid item lg={4} md={4} sm={12} xs={12} >
              <Typography variant="h4">Master Merek</Typography>
            </Grid>
            {/* <Grid item lg={4} md={4} sm={false} xs={false} /> */}
            <Grid item lg={8} md={8} sm={12} xs={12}>
              <Grid container spacing={2}>
                <Grid item lg={12} md={12} sm={12} xs={12} >
                  <div className={classes.sideRight}>
                    <Fab variant="extended" className={classes.btnAddJenis} onClick={() => addBrand()}>
                      <AddCircle className={classes.extendedIcon}/>
                      Merek
                    </Fab>
                  </div>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </div>

        <div className={classes.row}>
          <Grid container >
            <Grid item lg={12} md={12} sm={12} xs={12} >
              <BrandList editBrand={(brandData) => editBrand(brandData)}/>
            </Grid>
          </Grid>
        </div>
      </div>
    </Fragment>
  )
}

const mapStateToProps = state => {
  return {
    loadingState: state.brand.loading,
    changing: state.brand.changing,
    authRedirectPath: state.auth.authRedirectPath,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onSetAuthRedirect: (path) => dispatch(actions.setAuthRedirectPath(path)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Brand);