import axios from 'axios';

let token = localStorage.getItem('token');
const instance = axios.create({
  baseURL: `${process.env.REACT_APP_BASE_URL}`,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
    Authorization: token ? `Bearer ${token}` : ''
  }
});

instance.interceptors.response.use(
  (response) =>
    new Promise((resolve, reject) => {
      resolve(response)
    }),
  (error) => {
    console.log(error.response.status);
    if(error.response.status === 401){
      localStorage.clear();
      window.location.href = 'login';
      return ;
    }
    return Promise.reject(error);
  }
)

export default (instance);
