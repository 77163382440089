import React, {Fragment} from "react";
import {IconButton, TableCell, TableRow, Tooltip} from "@material-ui/core";
import {Edit as EditIcon} from "@material-ui/icons";

const Division = props => {
  const {division} = props;

  return (
    <Fragment>
      <TableRow key={division.id_division}>
        <TableCell>
          {division.name}
        </TableCell>
        <TableCell>
          {division.description}
        </TableCell>
        <TableCell style={{ width: 150 }} align="right">
          <Tooltip title="Edit Divisi">
            <IconButton aria-label="edit" onClick={props.edited}>
              <EditIcon />
            </IconButton>
          </Tooltip>
        </TableCell>
      </TableRow>
    </Fragment>
  )
}

export default Division;