import React, {Fragment} from "react";
import {IconButton, TableCell, TableRow, Tooltip} from "@material-ui/core";
import {CloudDownload} from "@material-ui/icons";
import moment from "moment";
import Chip from "@material-ui/core/Chip";

const Report = props => {
  const {report,classes,from,index} = props;
  const status = <Chip
    variant="outlined"
    size="medium"
    label={report.status_name === 'Siap Download' ? 'Siap Download' : 'Proses Generate'}
    className={report.status_name === 'Siap Download' ? classes.chipSuccess : classes.chipWarning}
  />

  return (
    <Fragment>
      <TableRow key={report.id_report}>
        <TableCell>
          {from+index}
        </TableCell>
        <TableCell>
          {report.name}
        </TableCell>
        <TableCell>
          {moment(report.created_at).format('dddd, DD MMMM YYYY, HH:mm:ss')}
        </TableCell>
        <TableCell>
          {status}
        </TableCell>
        <TableCell>
          <Tooltip title="Download Laporan">
            <IconButton aria-label="manajemen" disabled={report.status !== "1"} className={classes.btnDownload} size={"medium"}>
              <a href={report.document} target='_blank' rel="noopener noreferrer" style={{ color: 'inherit'}}>
                <CloudDownload />
              </a>
            </IconButton>
          </Tooltip>
        </TableCell>
      </TableRow>
    </Fragment>
  );
};

export default Report;