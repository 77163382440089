import * as actions from './actionTypes'
import axios from '../../axios-orders'
import { setAlert } from './alert'
// import { setAlert } from './alert'

export const fetchEntityStart = () => {
  return {
    type: actions.FETCH_ENTITY_START
  }
}

export const fetchEntitySuccess = (entities) => {
  return {
    type: actions.FETCH_ENTITY_SUCCESS,
    entities: entities
  }
}

export const fetchEntityFail = (error) => {
  return {
    type: actions.FETCH_ENTITY_FAIL,
    error: error
  }
}

export const fetchEntity = (token) => {
  return dispatch => {
    dispatch(fetchEntityStart())
    axios.get('entity', {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    })
      .then(res => {
        dispatch(fetchEntitySuccess(res.data))
      })
      .catch(err => {
        dispatch(fetchEntityFail(err))
      })
  }
}

export const storeEntityStart = () => {
  return {
    type: actions.STORE_ENTITY_START
  }
}

export const storeEntitySuccess = () => {
  return {
    type: actions.STORE_ENTITY_SUCCESS,
  }
}

export const storeEntityFail = (error) => {
  return {
    type: actions.STORE_ENTITY_FAIL,
    error: error
  }
}

export const storeEntity = (storeData, token) => {

  return dispatch => {
    dispatch(storeEntityStart())
    axios.post('entity', storeData, {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    })
      .then(response => {
        dispatch(storeEntitySuccess());
        dispatch(setAlert(response.data.msg_str, 'success'))
      })
      .catch(error => {
        dispatch(storeEntityFail(error.response.data.throwable))
        dispatch(setAlert(error.response.data.msg_str, 'error'))
      })
  }
}

export const updateEntityStart = () => {
  return {
    type: actions.UPDATE_ENTITY_START
  }
}

export const updateEntitySuccess = () => {
  return {
    type: actions.UPDATE_ENTITY_SUCCESS,
  }
}

export const updateEntityFail = (error) => {
  return {
    type: actions.UPDATE_ENTITY_FAIL,
    error: error
  }
}

export const updateEntity = (id, storeData, token) => {

  return dispatch => {
    dispatch(updateEntityStart())
    axios.put('entity/'+ id, storeData, {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    })
      .then(response => {
        dispatch(updateEntitySuccess())
        dispatch(setAlert(response.data.msg_sys, 'success'))    
      })
      .catch(error => {
        dispatch(updateEntityFail(error.response.data.throwable))
        dispatch(setAlert(error.response.data.msg_sys, 'success'))
      })
  }
}

export const clearErrorEntity = () => {
  return {
    type: actions.CLEAR_ERROR_ENTITY
  }
}