import React, { Fragment, useEffect, useState } from 'react';
import { Grid, Paper, Typography, makeStyles, TextField, Button, CircularProgress as Loading, FormControl, InputLabel, Select, FormHelperText } from '@material-ui/core';

import * as yup from 'yup'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers'

import * as actions from '../../../../../../store/actions/index';
import { connect } from 'react-redux';
import moment from 'moment'

const useStyles = makeStyles(theme => ({
  row: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  formRoot: {
    borderRadius: 8,
    boxShadow: theme.shadows[5],
    marginBottom: theme.spacing(2),
    padding: theme.spacing(2, 4, 3)
  }
}));

const schema = yup.object().shape({
});

const AddHistory = props => {
  const classes = useStyles();
  const { onGiveAsset, loading, error, token, onClearError, assetDetail, entities, divisions, onFetchEntity, onFetchDivision } = props;
  // console.log(entities);
  useEffect(() => {
    onClearError()
    onFetchEntity(token)
    onFetchDivision(token)
  },[onClearError, onFetchEntity,onFetchDivision, token])

  const { register, handleSubmit } = useForm({
    resolver: yupResolver(schema)
  })
  
  const onSubmit = data => {
    data.uuid = assetDetail.uuid;
    data.input_date = moment.utc().format('YYYY-MM-DD HH:mm:ss');
    data.emp_id = '0';
    onGiveAsset(data, token);
  }
  
  const handleChange = (event) => {
    const target = event.target.name
    event.persist()
    
    if(event.target.type === 'checkbox'){
      setFormState((formState) => ({
        ...formState,
        [target]: {
          value: event.target.checked
        }
      }))
    }else{
      setFormState((formState) => ({
        ...formState,
        [target]: {
          value: event.target.value
        }
      }))
    }
  }
  
  const [formState, setFormState] = useState({
    uuid: {
      value: assetDetail.uuid
    },
    name: {
      value: ''
    },
    id_entity: {
      value: ''
    },
    id_division: {
      value: ''
    },
    location: {
      value: null
    },
    notes: {
      value: ''
    }
  });

  function isEmpty(obj) {
    for(var key in obj) {
        if(obj.hasOwnProperty(key))
            return false;
    }
    return true;
  }

  let optionEntity = '';
  if (!isEmpty(entities)) {
    optionEntity = (
      <Fragment>
        {entities.map((entity) => (        
          <option key={entity.id_entity} value={entity.id_entity}>{entity.name}</option>
        ))}
      </Fragment>
    )
  }

  let optionDivision = '';
  if (!isEmpty(divisions)) {
    optionDivision = (
      <Fragment>
        {divisions.map((division) => (
          <option key={division.id_division} value={division.id_division}>{division.name}</option>
        ))}
      </Fragment>
    )
  }

  let button = (loading ? <Loading /> : (
    <Button type="submit" variant="contained" style={{ backgroundColor: '#FF9300', color: '#FFFFFF' }} fullWidth disableElevation>
      Simpan
    </Button>
  ))

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <TextField
        variant="standard"
        name="uuid"
        inputRef={register}
        error={error.uuid ? true : false}
        helperText={error.uuid && error.uuid[0]}
        defaultValue={formState.uuid.value}
        type="hidden"
        onChange={handleChange}
      />
      <Grid container spacing={2}>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Paper className={classes.formRoot}>
            <div className={classes.row}>
              <Grid container spacing={2}>
                <Grid item lg={6} md={6} sm={12} xs={12}>
                  <Typography>Penggunaan Aset</Typography>
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item lg={6} md={6} sm={12} xs={12}>
                  <TextField
                    label="Nama Pengguna"
                    variant="outlined"
                    name="name"
                    fullWidth
                    inputRef={register}
                    error={error.name ? true : false}
                    helperText={error.name && error.name[0]}
                    defaultValue={formState.name.value}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item lg={6} md={6} sm={12} xs={12}>
                  <FormControl
                    error={error.id_entity ? true : false}
                    variant="outlined" className={classes.formControl} fullWidth>
                    <InputLabel htmlFor="outlined-age-native-simple">Entitas</InputLabel>
                    <Select
                      native
                      defaultValue={formState.id_entity.value}
                      onChange={handleChange}
                      label="Entitas"
                      inputProps={{
                        name: 'id_entity',
                        id: 'outlined-age-native-simple'
                      }}
                      name="id_entity"
                      inputRef={register}
                    >
                      <option aria-label="None" value="" />
                      {optionEntity}
                    </Select>
                    <FormHelperText>{error.id_entity && error.id_entity[0]}</FormHelperText>
                  </FormControl>
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item lg={6} md={6} sm={12} xs={12}>
                  <FormControl
                    error={error.id_division ? true : false}
                    variant="outlined" className={classes.formControl} fullWidth>
                    <InputLabel htmlFor="outlined-age-native-simple">Divisi</InputLabel>
                    <Select
                      native
                      defaultValue={formState.id_division.value}
                      onChange={handleChange}
                      label="Divisi"
                      inputProps={{
                        name: 'id_division',
                        id: 'outlined-age-native-simple'
                      }}
                      name="id_division"
                      inputRef={register}
                    >
                      <option aria-label="None" value="" />
                      {optionDivision}
                    </Select>
                    <FormHelperText>{error.id_division && error.id_division[0]}</FormHelperText>
                  </FormControl>
                </Grid>
                {/* <Grid item lg={6} md={6} sm={12} xs={12}> */}
                  {/* <TextField
                    // label="No Karyawan"
                    variant="standard"
                    name="emp_id"
                    // fullWidth
                    type="hidden"
                    inputRef={register}
                    error={error.emp_id ? true : false}
                    helperText={error.emp_id && error.emp_id[0]}
                    disabled={true}
                    defaultValue={formState.emp_id.value}
                    onChange={handleChange}
                  /> */}
                {/* </Grid> */}
              </Grid>
            </div>
            <div className={classes.row}>
              <Grid container spacing={2}>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <TextField
                    label="Keterangan"
                    variant="outlined"
                    name="notes"
                    fullWidth
                    inputRef={register}
                    error={error.notes ? true : false}
                    helperText={error.notes && error.notes[0]}

                    defaultValue={formState.notes.value}
                    onChange={handleChange}
                  />
                </Grid>
              </Grid>
            </div>
            <div className={classes.row}>
              <Grid container spacing={2}>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <TextField
                    label="Lokasi"
                    variant="outlined"
                    name="location"
                    placeholder="exp. (tempat instansi, alamat lengkap)Rumah Sakit A, petukangan Jakarta Selatan"
                    fullWidth
                    multiline
                    rows={4}
                    inputRef={register}
                    error={error.location ? true : false}
                    helperText={error.location && error.location[0]}

                    defaultValue={formState.location.value}
                    onChange={handleChange}
                  />
                </Grid>
              </Grid>
            </div>
          </Paper>
        </Grid>
      </Grid>

      <Grid container spacing={2}>
        <Grid item lg={8} md={8} sm={12} xs={12}>
        </Grid>
        <Grid item lg={4} md={4} sm={12} xs={12}>
          <Grid container spacing={2}>
            <Grid item lg={6} md={6} sm={12} xs={12}>
              <Button variant="contained" fullWidth onClick={props.closedModalDialog} disableElevation>
                Batal
              </Button>
            </Grid>
            <Grid item lg={6} md={6} sm={12} xs={12}>
              {button}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </form>
  );
};

const mapStateToProps = state => {
  return {
    loading: state.assetDetail.loading,
    error: state.assetDetail.error,
    token: state.auth.token,
    entities: state.entity.entities,
    divisions: state.division.divisions
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onFetchEntity: (token) => dispatch(actions.fetchEntity(token)),
    onFetchDivision: (token) => dispatch(actions.fetchDivision(token)),
    onClearError: () => dispatch(actions.clearError()),
    onGiveAsset: (storeData, token) => dispatch(actions.storeGiveAsset(storeData, token)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AddHistory);