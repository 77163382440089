import React, { Fragment, useEffect } from 'react'
import Main from './hoc/Layout/Main/Main'
// import Minimal from './hoc/Layout/Minimal/Minimal'
import { Switch, Route, Redirect } from 'react-router-dom'

import Login from './containers/Auth/Login/Login'
import Logout from './containers/Auth/Logout/Logout'
import Dashboard from './containers/Dashboard/Dashboard'
import Profile from './containers/Profile/Profile'
import AsetWakaf from './containers/AsetWakaf/AsetWakaf'
// import ManajemenAsets from './containers/ManajemenAsets/ManajemenAsets'
import Histories from './components/View/ManajemenAset/Histories/Histories'
import Bank from './containers/Testing/Bank/Bank'
import Master from './containers/Master/Master'
import Notification from './containers/Notifications/Notifications'
import LaporanAsetWakaf from './containers/Laporan/AsetWakaf/AsetWakaf';
// import LaporanQRAsetWakaf from './containers/Laporan/QRAsetWakaf/QRAsetWakaf';
import Divison from './containers/Master/Division/Division';
import Entity from './containers/Master/Entity/Entity';
import Kategori from './containers/Master/Kategori/Kategori';
import Jenis from './containers/Master/Jenis/Jenis';
import Brand from "./containers/Master/Brand/Brand";
import ScrollToTop from './hoc/ScrollToTop/ScrollToTop';

import * as actions from './store/actions/index'
import { connect } from 'react-redux'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import MomentUtils from '@date-io/moment';

// Firebase Initial
import { messaging } from "./init-fcm";

import { Router } from 'react-router-dom'
import { createBrowserHistory } from 'history'
//
// Clear Cache
import { useClearCache } from 'react-clear-cache';
import moment from "moment";
import 'moment/locale/id';
import {Dialog} from "@material-ui/core";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
moment.locale('in')

// import Dialog from '@material-ui/core/Dialog';
// import DialogActions from '@material-ui/core/DialogActions';
// import DialogContent from '@material-ui/core/DialogContent';
// import DialogContentText from '@material-ui/core/DialogContentText';
// import DialogTitle from '@material-ui/core/DialogTitle';
// import Button from '@material-ui/core/Button';


const history = createBrowserHistory();

const App = props => {
  const { isLatestVersion, emptyCacheStorage } = useClearCache({duration: 5000});
  const { onSetAlert, token, onFetchNotification } = props
  
  function isEmpty(obj) {
    for(var key in obj) {
        if(obj.hasOwnProperty(key))
            return false;
    }
    return true;
  }

  const fetchData = async () => {
    
    navigator.serviceWorker.addEventListener("message", (message) => {
      if(!isEmpty(token)){
        onFetchNotification(1, token)
      }
      onSetAlert('notifikasi baru', 'warning')
      console.log(message.data)
    });
  } 
  // const fetchData = async () => {
  //   messaging.requestPermission()
  //   .then(async function() {
  //     localStorage.setItem('tokenFirebase', await messaging.getToken())
  //     // console.log(token);
  //   })
  //   .catch(function(err) {
  //     console.log("Unable to get permission to notify.", err);
  //   });
  //   navigator.serviceWorker.addEventListener("message", (message) => {
  //     if(!isEmpty(token)){
  //       onFetchNotification(1, token)
  //     }
  //     onSetAlert('notifikasi baru', 'warning')
  //     console.log(message.data)
  //   });
  //   // navigator.serviceWorker.addEventListener("message", (message) => console.log(message.data));
  // }

  useEffect(() => {
    const elem = document.getElementById('startingLoader');
    window.onload = () => {
      if (elem) {
        elem.remove();
      }
    };
    if(messaging !== undefined){
      fetchData();
    }
  })

  // useEffect(() => {
  //   onCheckAuth();
  // }, [onCheckAuth])

  //

  let routes = (
    <Switch>
      <Redirect
        exact
        from="/"
        to="/dashboard"/>
      <RouteWrapper exact path="/manajemen-aset/:uuid" component={Histories} layout={Main}/>
      <RouteWrapper exact path="/manajemen-aset" component={AsetWakaf} layout={Main}/>
      <RouteWrapper exact path="/bank" component={Bank} layout={Main}/>
      {/* <RouteWrapper path="/manajemen-aset/:uuid" component={Histories} layout={Main}/>
        <RouteWrapper path="/manajemen-aset" component={ManajemenAsets} layout={Main}/> */}
      <RouteWrapper exact path="/master" component={Master} layout={Main} />
      <RouteWrapper exact path="/divisi" component={Divison} layout={Main} />
      <RouteWrapper exact path="/merek" component={Brand} layout={Main} />
      <RouteWrapper exact path="/entitas" component={Entity} layout={Main} />
      <RouteWrapper exact path="/kategori" component={Kategori} layout={Main} />
      <RouteWrapper exact path="/jenis" component={Jenis} layout={Main} />
      <RouteWrapper exact path="/notification" component={Notification} layout={Main} />
      <RouteWrapper exact path="/laporan-aset" component={LaporanAsetWakaf} layout={Main} />
      {/*<RouteWrapper path="/laporan-qr-aset" component={LaporanQRAsetWakaf} layout={Main} />*/}

      <RouteWrapper path="/profile" exact component={Profile} layout={Main}/>
      <RouteWrapper  path="/dashboard" exact component={Dashboard} layout={Main}/>
      <RouteWrapper exact path="/logout" component={Logout} layout={Main}/>
      <Route path="/login" component={Login}/>
      <Redirect to="/dashboard"/>
    </Switch>
  )

  return (
    <Fragment>
      <Router history={history}>
        <ScrollToTop />
        {!isLatestVersion && (
          // () => emptyCacheStorage()
          <Dialog
            open={true}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">{"Update info"}</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Update aplikasi nya dulu yuk
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={e => {
                  e.preventDefault();
                  emptyCacheStorage();
                }}
                color="primary"
                autoFocus
              >
                Ya
              </Button>
            </DialogActions>
          </Dialog>
        )}
        <MuiPickersUtilsProvider utils={MomentUtils}>
          {routes}
        </MuiPickersUtilsProvider>
      </Router>
    </Fragment>
  )
}

const RouteWrapper = props => {
  const {
    component: Component,
    layout: Layout,
    ...rest
  } = props;

  const token = localStorage.getItem('token');

  return (
    <Route {...rest} render={(matchProps) =>
      token ? (
        <Layout {...matchProps}>
          <Component {...matchProps} />
        </Layout>
      ) : (
        <Redirect to={'/login'}/>
      )

    } />
  )
}

const mapStateToProps = state => {
  return {
    redirectPath: state.auth.authRedirectPath,
    isAuthenticated: state.auth.token !== null,
    token: state.auth.token
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    onCheckAuth: () => dispatch(actions.authCheckState()),
    onSetAlert: (msg, status) => dispatch(actions.setAlert(msg, status)),
    onFetchNotification: (page, token) => dispatch(actions.fetchNotifications(page, token))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(App)
