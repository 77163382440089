import * as actions from './actionTypes'
import axios from '../../axios-orders'
import { setAlert } from './alert'

export const downloadAssetsStart = () => {
  return {
    type: actions.DOWNLOAD_LAPORAN_ASSETS_START
  }
}

export const downloadAssetsSuccess = (file) => {
  return {
    type: actions.DOWNLOAD_LAPORAN_ASSETS_SUCCESS,
    file: file
  }
}

export const downloadAssetsFail = (error) => {
  return {
    type: actions.DOWNLOAD_LAPORAN_ASSETS_FAIL,
    error: error
  }
}

export const downloadAssets = (storeData, token) => {
  let param = 'export_mode='+storeData.export_mode+'&date_from='+storeData.date_from+'&date_to='+storeData.date_to+'&id_cat='+storeData.id_cat+'&id_type='+storeData.id_type+'&id_entity='+storeData.id_entity;
  return dispatch => {
    dispatch(downloadAssetsStart())
    axios.get('assets/report/all?' + param, {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    })
      .then(res => {
        // fileDownload(res.data, 'download-asset.xlsx')
        dispatch(downloadAssetsSuccess(res.data))
        dispatch(setAlert(res.data.msg_str, 'success'))
      })
      .catch(err => {
        dispatch(downloadAssetsFail(err.response.data.msg_str))
        dispatch(setAlert('download failed', 'error'))
      })
  }
}

export const downloadQRAssetsStart = () => {
  return {
    type: actions.DOWNLOAD_LAPORAN_QR_ASSETS_START
  }
}

export const downloadQRAssetsSuccess = (file) => {
  return {
    type: actions.DOWNLOAD_LAPORAN_QR_ASSETS_SUCCESS,
    file: file
  }
}

export const downloadQRAssetsFail = (error) => {
  return {
    type: actions.DOWNLOAD_LAPORAN_QR_ASSETS_FAIL,
    error: error
  }
}

export const downloadQRAssets = (storeData, token) => {
  let param = 'date_from='+storeData.date_from+'&date_to='+storeData.date_to+'&id_cat='+storeData.id_cat+'&id_type='+storeData.id_type+'&id_entity='+storeData.id_entity;
  return dispatch => {
    dispatch(downloadQRAssetsStart())
    axios.get('assets/report/bulkqr?' + param, {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`
      },
      // responseType: 'blob'
    })
      .then(res => {
        // fileDownload(res.data, 'download-qr-asset.pdf')
        dispatch(downloadQRAssetsSuccess(res.data))
        dispatch(setAlert(res.data.msg_str, 'success'))
      })
      .catch(err => {
        dispatch(downloadQRAssetsFail(err.response.data.msg_str))
        dispatch(setAlert('download failed', 'error'))
      })
  }
}

export const fetchListReportsStart = () => {
  return {
    type: actions.FETCH_LIST_REPORTS_START
  }
}

export const fetchListReportsSuccess = (reports) => {
  return {
    type: actions.FETCH_LIST_REPORTS_SUCCESS,
    reports: reports
  }
}

export const fetchListReportsFail = (error) => {
  return {
    type: actions.FETCH_LIST_REPORTS_FAIL,
    error: error
  }
}

export const fetchListReports = (page, token) => {
  // let param = '&id_type='+formSearch.id_type+'&id_cat='+formSearch.id_cat+'&name='+formSearch.name+'&status='+formSearch.status+'&id_entity='+formSearch.id_entity+'&end_date='+formSearch.end_date+'&id_brand='+formSearch.id_brand+'&kind='+formSearch.kind;
  // let param = '&id_type='+formSearch.id_type+'&id_cat='+formSearch.id_cat+'&name='+formSearch.name+'&end_date='+formSearch.end_date;
  return dispatch => {
    dispatch(fetchListReportsStart())
    axios.get('assets/report/list?page=' + page + '&sort_by=created_at&order_by=desc', {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    })
      .then(res => {
        dispatch(fetchListReportsSuccess(res.data))
      })
      .catch(err => {
        dispatch(fetchListReportsFail(err))
      })
  }
}