import * as actions from './actionTypes'
import axios from '../../axios-orders'

export const fetchNotificationsStart = () => {
  return {
    type: actions.FETCH_NOTIFICATIONS_START
  }
}

export const fetchNotificationsSuccess = (notifications, current_page, per_page, total, unread_count) => {
  return {
    type: actions.FETCH_NOTIFICATIONS_SUCCESS,
    notifications: {
      notifications,
      current_page,
      per_page,
      total
    },
    unread_count: unread_count,
  }
}

export const fetchNotificationsFail = (error) => {
  return {
    type: actions.FETCH_NOTIFICATIONS_FAIL,
    error: error
  }
}

export const fetchNotifications = (page, token) => {
  return dispatch => {
    dispatch(fetchNotificationsStart())
    axios.get('notification?page=' + page, {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    })
      .then(res => {
        const fetchedNotifications = []
        for (const key in res.data.notification.data) {
          fetchedNotifications.push({
            ...res.data.notification.data[key]
          })
        }
        dispatch(fetchNotificationsSuccess(fetchedNotifications, res.data.notification.current_page, res.data.notification.per_page, 
          res.data.notification.total, res.data.unread_count))
      })
      .catch(err => {
        dispatch(fetchNotificationsFail(err.response.data.msg_str))
      })
  }
}

export const readNotificationsStart = () => {
  return {
    type: actions.READ_NOTIFICATIONS_START
  }
}

export const readNotificationsSuccess = () => {
  return {
    type: actions.READ_NOTIFICATIONS_SUCCESS,
  }
}

export const readNotificationsFail = (error) => {
  return {
    type: actions.READ_NOTIFICATIONS_FAIL,
    error: error
  }
}

export const readNotifications = (token) => {
  return dispatch => {
    dispatch(readNotificationsStart())
    axios.get('notification/read', {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    })
      .then(res => {
        dispatch(readNotificationsSuccess())
      })
      .catch(err => {
        dispatch(readNotificationsFail(err.response.data.msg_str))
      })
  }
}