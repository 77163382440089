import * as actions from './actionTypes'
import axios from '../../axios-orders'
import { setAlert } from './alert'

export const fetchDivisionStart = () => {
  return {
    type: actions.FETCH_DIVISION_START
  }
}

export const fetchDivisionSuccess = (divisions) => {
  return {
    type: actions.FETCH_DIVISION_SUCCESS,
    divisions: divisions
  }
}

export const fetchDivisionFail = (error) => {
  return {
    type: actions.FETCH_DIVISION_FAIL,
    error: error
  }
}

export const fetchDivision = (token) => {
  return dispatch => {
    dispatch(fetchDivisionStart())
    axios.get('division', {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    })
      .then(res => {
        dispatch(fetchDivisionSuccess(res.data))
      })
      .catch(err => {
        dispatch(fetchDivisionFail(err))
      })
  }
}

export const storeDivisionStart = () => {
  return {
    type: actions.STORE_DIVISION_START
  }
}

export const storeDivisionSuccess = () => {
  return {
    type: actions.STORE_DIVISION_SUCCESS,
  }
}

export const storeDivisionFail = (error) => {
  return {
    type: actions.STORE_DIVISION_FAIL,
    error: error
  }
}

export const storeDivision = (storeData, token) => {

  return dispatch => {
    dispatch(storeDivisionStart())
    axios.post('division', storeData, {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    })
      .then(response => {
        dispatch(storeDivisionSuccess());
        dispatch(setAlert(response.data.msg_str, 'success'))
      })
      .catch(error => {
        dispatch(storeDivisionFail(error.response.data.throwable))
        dispatch(setAlert(error.response.data.msg_str, 'error'))
      })
  }
}

export const updateDivisionStart = () => {
  return {
    type: actions.UPDATE_DIVISION_START
  }
}

export const updateDivisionSuccess = () => {
  return {
    type: actions.UPDATE_DIVISION_SUCCESS,
  }
}

export const updateDivisionFail = (error) => {
  return {
    type: actions.UPDATE_DIVISION_FAIL,
    error: error
  }
}

export const updateDivision = (id, storeData, token) => {

  return dispatch => {
    dispatch(updateDivisionStart())
    axios.put('division/'+ id, storeData, {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    })
      .then(response => {
        dispatch(updateDivisionSuccess())
        dispatch(setAlert(response.data.msg_sys, 'success'))
      })
      .catch(error => {
        dispatch(updateDivisionFail(error.response.data.throwable))
        dispatch(setAlert(error.response.data.msg_sys, 'success'))
      })
  }
}

export const clearErrorDivision = () => {
  return {
    type: actions.CLEAR_ERROR_DIVISION
  }
}