import React, { Fragment } from 'react';
import { IconButton, TableCell, TableRow, Tooltip } from '@material-ui/core'
import { Edit as EditIcon } from '@material-ui/icons'
import moment from 'moment';

const TableBodyHistory = props => {
  const { history } = props;
  
  const return_date = history.return_date ? moment(history.return_date).subtract(new Date().getTimezoneOffset(), 'minutes').format('DD/MM/YY HH:mm:ss') : '-';
  const input_date = history.input_date ? moment(history.input_date).subtract(new Date().getTimezoneOffset(), 'minutes').format('DD/MM/YY HH:mm:ss') : '-';
  return (
    <Fragment>
      <TableRow key={history.id_history}>
        <TableCell align="left">
          {input_date}
        </TableCell>
        <TableCell align="left">
          {return_date}
        </TableCell>
        <TableCell>
          {history.name}
        </TableCell>
        <TableCell>
          {history.entity ? history.entity.name : '-'}
        </TableCell>
        <TableCell>
          {history.division ? history.division.name : '-'}
        </TableCell>
        <TableCell>
          {history.notes}
        </TableCell>
        <TableCell>
          {history.location}
        </TableCell>
        <TableCell>
          <Tooltip title="Edit Tanggal Pengembalian">
            <IconButton aria-label="detail" onClick={props.editHistory}>
              <EditIcon />
            </IconButton>
          </Tooltip>
        </TableCell>
      </TableRow>
    </Fragment>
  );
}

export default TableBodyHistory;