import * as actions from './actionTypes'
import axios from '../../axios-orders'

export const dashboardStart = () => {
  return {
    type: actions.DASHBOARD_START
  }
}

export const dashboardSuccess = (dashboard) => {
  return {
    type: actions.DASHBOARD_SUCCESS,
    dashboard: dashboard,
  }
}

export const dashboardFail = (error) => {
  return {
    type: actions.DASHBOARD_FAIL,
    error: error
  }
}

export const dashboard = (token) => {
  return dispatch => {
    dispatch(dashboardStart())
    axios.get('users/dashboard', {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    })
      .then(response => {
        dispatch( dashboardSuccess( response.data ) );
      })
      .catch(error => {
        dispatch(dashboardFail(error.response.data.msg_str))
      })
  }
}