import React, { Fragment, useEffect, useState } from 'react';
import { Grid, makeStyles, Typography, CircularProgress as Loading, Fab } from '@material-ui/core';

import EntityList from '../../../components/View/Master/Entity/EntityList/EntityList';
import Modal from '../../../components/UI/Modal/ScrollDialog/ScrollDialog';

import { useHistory } from 'react-router-dom';
import * as actions from '../../../store/actions/index';
import { connect } from 'react-redux';
import { AddCircle } from '@material-ui/icons';
// import palette from '../../../theme/palette'
import AddEntity from '../../../components/View/Master/Entity/AddEntity/AddEntity';
import EditEntity from '../../../components/View/Master/Entity/EditEntity/EditEntity';

const useStyles = makeStyles(theme => ({
  row: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  contentSpace: {
    padding: '10px 32px'
    // backgroundColor: '#EEEEEE'
  },
  fab: {
    position: 'fixed',
    bottom: theme.spacing(4),
    right: theme.spacing(2)
  },
  sideRight: {
    textAlign: 'right'
  },
  btnAdd: {
    backgroundColor: '#FF9300',
    '&:hover': {
      backgroundColor: '#FF9300'
    },
    color: '#FFFFFF',
    width: '100%',
    justifyContent: 'left'
  }
  ,
  btnAddJenis: {
    backgroundColor: '#FF9300',
    '&:hover': {
      backgroundColor: '#FF9300'
    },
    color: '#FFFFFF',
    // width: '100%',
    // justifyContent: 'cen'
  },
  extendedIcon: {
    marginRight: theme.spacing(1),
    
  },
}))

const Master = props => {
  const { loadingState, onSetAuthRedirect, changing, changingUpdate, authRedirectPath } = props;
  const classes = useStyles();
  const path = useHistory().location.pathname;

  useEffect(() => {
    if(authRedirectPath !== path){
      onSetAuthRedirect(path)
    }
    closedModalDialog()
  }, [onSetAuthRedirect, authRedirectPath, path, changing])

  useEffect(() => {
    closedModalDialog()
  }, [changingUpdate])

  let loading = '';
  if(loadingState){
    loading = <Loading />
  }

  const [modalState, setModalState] = useState({
    maxWidth: 'sm',
    title: '',
    open: false,
  })

  const [form, setForm] = useState('');

  const addEntity = () => {
    setModalState({
      maxWidth: 'xs',
      title: 'Tambah Entitas',
      open: true,
    });
    setForm(<AddEntity closedModalDialog={() => closedModalDialog()}/>);
  }

  const editEntity = (entity) => {
    setModalState({
      maxWidth: 'xs',
      title: 'Edit Entitas',
      open: true,
    });
    setForm(<EditEntity entityData={entity} closedModalDialog={() => closedModalDialog()}/>);
  }

  const closedModalDialog = () => {
    setModalState({
      maxWidth: 'sm',
      title: '',
      open: false,
    });
    setForm('');
  }

  return (
    <Fragment>
      <div className={classes.contentSpace}>
        {loading}

        <Modal
          maxWidth={modalState.maxWidth}
          open={modalState.open}
          title={modalState.title}
          onCloseModal={closedModalDialog}
          contentModal={form}
        />
        
        <div className={classes.row}>
          <Grid container >
            <Grid item lg={4} md={4} sm={12} xs={12} >
              <Typography variant="h4">Master Entitas</Typography>
            </Grid>
            {/* <Grid item lg={4} md={4} sm={false} xs={false} /> */}
            <Grid item lg={8} md={8} sm={12} xs={12}>
              <Grid container spacing={2}>
                <Grid item lg={12} md={12} sm={12} xs={12} >
                  <div className={classes.sideRight}>                    
                    <Fab variant="extended" className={classes.btnAddJenis} onClick={() => addEntity()}>
                      <AddCircle className={classes.extendedIcon}/>
                      Entitas
                    </Fab>                      
                  </div>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </div>

        <div className={classes.row}>
          <Grid container >
            <Grid item lg={12} md={12} sm={12} xs={12} >
              <EntityList editEntity={(entityData) => editEntity(entityData)}/>
            </Grid>
          </Grid>
        </div>
      </div>
    </Fragment>
  );
};

const mapStateToProps = state => {
  return {
    loadingState: state.entity.loading,
    changing: state.entity.changing,
    changingUpdate: state.entity.changingUpdate,
    authRedirectPath: state.auth.authRedirectPath,
    entities: state.entity.entities,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onSetAuthRedirect: (path) => dispatch(actions.setAuthRedirectPath(path)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Master);