import React, { Fragment, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../../store/actions/index';

import { Button, FormControl, Grid, InputLabel, makeStyles, Paper, Select, Typography, CircularProgress as Loading } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { DatePicker } from "@material-ui/pickers";
import moment from 'moment';

import Reports from "../../../components/View/Laporan/Reports";

const useStyles = makeStyles(theme => ({
  row: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  contentSpace: {
    padding: '10px 32px'
    // backgroundColor: '#EEEEEE'
  },
  paperPadding: {
    padding: '20px 30px'
  },
  paperTag: {
    marginBottom: 20,
  },
  btnDownload: {
    color: theme.palette.success.main
  },
  btnGenerate: {
    backgroundColor: theme.palette.warning.main,
    color: theme.palette.white,
    textTransform: 'none',
    '&:hover': {
      backgroundColor: theme.palette.warning.dark
    }
  },
  chipWarning: {
    borderColor: theme.palette.warning.main,
    color:theme.palette.warning.main
  },
  chipSuccess: {
    borderColor: theme.palette.success.main,
    color:theme.palette.success.main
  }
}));

const AsetWakaf = props => {
  const classes = useStyles();
  const path = useHistory().location.pathname;

  const {onSetAuthRedirect, token, onFetchTypeAsset, onFetchCategory, categories, typeAssets, onDownloadAssets, loading,onFetchEntity,entities,onFetchListReports,reports,changing,onDownloadQRAssets} = props;

  useEffect(() => {
    onSetAuthRedirect(path)
    onFetchTypeAsset(token)
    onFetchCategory(token)
    onFetchEntity(token)
    onFetchListReports(1, token)
  }, [onSetAuthRedirect, path, token, onFetchTypeAsset, onFetchCategory,onFetchEntity, onFetchListReports,changing]);

  const handleChangePage = (newPage) => {
    onFetchListReports(newPage, token)
  }

  let optionType = '';
  let optionCategories ='';
  let optionEntities ='';

  function isEmpty(obj) {
    for(var key in obj) {
        if(obj.hasOwnProperty(key))
            return false;
    }
    return true;
  }

  if (!isEmpty(typeAssets)) {
    optionType = (
      <Fragment>
        {typeAssets.map((type) => (        
          <option key={type.id_type} value={type.id_type}>{type.name}</option>
        ))}
      </Fragment>
    )
  }

  if (!isEmpty(categories)) {
    optionCategories = (
      <Fragment>
        {categories.map((category) => (        
          <option key={category.id_cat} value={category.id_cat}>{category.name}</option>
        ))}
      </Fragment>
    )
  }

  if (!isEmpty(entities)) {
    optionEntities = (
      <Fragment>
        {entities.map((entity) => (
          <option key={entity.id_entity} value={entity.id_entity}>{entity.name}</option>
        ))}
      </Fragment>
    )
  }

  const [formState, setFormState] = useState({
    id_type: '',
    id_cat: '',
    id_entity: '',
    export_mode: 'xlsx'
  })

  const [startDate, setStartDate] = useState(Date());
  const handleDateChange = event => {
    setStartDate(moment(event));
  }

  const [endDate, setEndDate] = useState(Date());
  const handleEndDateChange = event => {
    setEndDate(moment(event));
  }

  const handleChange = (event) => {
    const target = event.target.name
    event.persist()
    
    if(event.target.type === 'checkbox'){
      setFormState((formState) => ({
        ...formState,
        [target]: event.target.checked
      }))
    }else{
      setFormState((formState) => ({
        ...formState,
        [target]: event.target.value
      }))
    }
  }

  const generateReport = () => {
    let data = formState;
    if (startDate) {
      let date_from = moment(startDate).toDate();
      date_from.setDate(1)
      date_from.setHours(0);
      date_from.setMinutes(0);
      date_from.setSeconds(0);
      data.date_from = moment.utc(date_from).format('YYYY-MM-DD HH:mm:ss');
    }

    if(endDate){
      let date_to = moment(endDate).toDate();
      let y = date_to.getFullYear();
      let m = date_to.getMonth();

      date_to.setDate(new Date(y, m + 1, 0).getDate())
      date_to.setHours(23);
      date_to.setMinutes(59);
      date_to.setSeconds(59);

      data.date_to = moment.utc(date_to).format('YYYY-MM-DD HH:mm:ss');
    }

    data.export_mode = 'xlsx';

    onDownloadAssets(data, token);
  }

  const generateQRAssets = () => {
    let data = formState;
    if (startDate) {
      let date_from = moment(startDate).toDate();
      date_from.setDate(1)
      date_from.setHours(0);
      date_from.setMinutes(0);
      date_from.setSeconds(0);
      data.date_from = moment.utc(date_from).format('YYYY-MM-DD HH:mm:ss');
    }

    if(endDate){
      let date_to = moment(endDate).toDate();
      let y = date_to.getFullYear();
      let m = date_to.getMonth();

      date_to.setDate(new Date(y, m + 1, 0).getDate())
      date_to.setHours(23);
      date_to.setMinutes(59);
      date_to.setSeconds(59);

      data.date_to = moment.utc(date_to).format('YYYY-MM-DD HH:mm:ss');
    }

    onDownloadQRAssets(data, token);
  }

  const refresh = () => {
    onFetchListReports(1, token)
  }
  
  return (
    <Fragment>
      <div className={classes.contentSpace}>
        {/* {loading} */}
        <div className={classes.row}>
          <Grid container >
            <Grid item lg={8} md={8} sm={12} xs={12} >
              <Typography variant="h4">Laporan Aset</Typography>
            </Grid>
          </Grid>
        </div>

        <div className={classes.row}>
          <Typography variant="h6">Filter</Typography><br/>
          <Grid container spacing={2}>
            <Grid item lg={9} md={9} sm={12} xs={12}>
              <Grid container spacing={2}>
                <Grid item lg={4} md={4} sm={12} xs={12}>
                  <Paper component="form" className={classes.searchSelectRoot}>
                    <FormControl
                      // error={errors.id_type && true}
                      variant="outlined" className={classes.formControl} fullWidth>
                      <InputLabel htmlFor="outlined-age-native-simple">Semua Jenis</InputLabel>
                      <Select
                        native
                        value={formState.id_type}
                        onChange={handleChange}
                        label="Semua Jenis"
                        inputProps={{
                          name: 'id_type',
                          id: 'outlined-age-native-simple'
                        }}
                        name="id_type"
                      >
                        <option aria-label="None" value="" />
                        {optionType}
                      </Select>
                      {/* <FormHelperText>{errors.id_type && errors.id_type.message}</FormHelperText> */}
                    </FormControl>
                  </Paper>
                </Grid>
                <Grid item lg={4} md={4} sm={12} xs={12}>
                  <Paper component="form" className={classes.searchSelectRoot}>
                    <FormControl
                      // error={errors.id_cat && true}
                      variant="outlined" className={classes.formControl} fullWidth>
                      <InputLabel htmlFor="outlined-age-native-simple">Semua Kategori</InputLabel>
                      <Select
                        native
                        value={formState.id_cat}
                        onChange={handleChange}
                        label="Semua Kategori"
                        inputProps={{
                          name: 'id_cat',
                          id: 'outlined-age-native-simple'
                        }}
                        name="id_cat"
                      >
                        <option aria-label="None" value="" />
                        {optionCategories}
                      </Select>
                      {/* <FormHelperText>{errors.id_cat && errors.id_cat.message}</FormHelperText> */}
                    </FormControl>
                  </Paper>
                </Grid>
                <Grid item lg={4} md={4} sm={12} xs={12}>
                  <Paper component="form" className={classes.searchSelectRoot}>
                    <FormControl
                      // error={errors.id_cat && true}
                      variant="outlined" className={classes.formControl} fullWidth>
                      <InputLabel htmlFor="outlined-age-native-simple">Semua Entitas</InputLabel>
                      <Select
                        native
                        value={formState.id_entity}
                        onChange={handleChange}
                        label="Semua Entitas"
                        inputProps={{
                          name: 'id_entity',
                        }}
                        name="id_entity"
                      >
                        <option aria-label="None" value="" />
                        {optionEntities}
                      </Select>
                      {/* <FormHelperText>{errors.id_cat && errors.id_cat.message}</FormHelperText> */}
                    </FormControl>
                  </Paper>
                </Grid>
                <Grid item lg={4} md={4} sm={12} xs={12}>
                  <Paper component="form" className={classes.searchSelectRoot}>
                    <DatePicker
                      variant="dialog"
                      openTo="year"
                      views={["year", "month"]}
                      label="Start Date"
                      helperText="Start from year selection"
                      disableFuture
                      value={startDate}
                      onChange={handleDateChange}
                      fullWidth
                      name="date_from"
                    />
                  </Paper>
                </Grid>
                <Grid item lg={4} md={4} sm={12} xs={12}>
                  <Paper component="form" className={classes.searchSelectRoot}>
                    <DatePicker
                      variant="dialog"
                      openTo="year"
                      views={["year", "month"]}
                      label="End Date"
                      helperText="End from year selection"
                      disableFuture
                      value={endDate}
                      onChange={handleEndDateChange}
                      fullWidth
                      name="date_to"
                    />
                  </Paper>
                </Grid>

              </Grid>
            </Grid>
            <Grid item lg={3} md={3} sm={12} xs={12}>
              <Paper className={classes.paperPadding}>
                <Grid container spacing={2}>
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <Button variant={"contained"} className={classes.btnGenerate} fullWidth onClick={() => generateReport()}>
                      Generate Laporan Aset
                    </Button>
                  </Grid>
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <Button variant={"contained"} className={classes.btnGenerate} fullWidth onClick={() => generateQRAssets()}>
                      Generate QR Code Asset
                    </Button>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
          </Grid>
        </div>
        <hr />
       <div className={classes.row}>
         {loading ? <Loading /> : <Reports reports={reports} classes={classes} propsHandleChangePage={(page) => handleChangePage(page)} refresh={() => refresh()}/>}

       </div>

      </div>

    </Fragment>
  )
}

const mapStateToProps = state => {
  return {
    token: state.auth.token,
    typeAssets: state.typeAsset.typeAssets,
    categories: state.category.categories,
    entities: state.entity.entities,
    loading: state.laporan.loading,
    changing: state.laporan.changing,
    reports: state.laporan.reports
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onSetAuthRedirect: (path) => dispatch(actions.setAuthRedirectPath(path)),
    onFetchTypeAsset: (token) => dispatch(actions.fetchTypeAssets(token)),
    onFetchCategory: (token) => dispatch(actions.fetchCategory(token)),
    onFetchEntity: (token) => dispatch(actions.fetchEntity(token)),
    onFetchListReports: (page, token) => dispatch(actions.fetchListReports(page, token)),
    onDownloadAssets: (data, token) => dispatch(actions.downloadAssets(data, token)),
    onDownloadQRAssets: (data, token) => dispatch(actions.downloadQRAssets(data, token)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AsetWakaf);