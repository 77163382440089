export const AUTH_START = 'AUTH_START'
export const AUTH_SUCCESS = 'AUTH_SUCCESS'
export const AUTH_FAIL = 'AUTH_FAIL'
export const AUTH_LOGOUT = 'AUTH_LOGOUT'

export const AUTH_CHANGE_PASSWORD_START = 'AUTH_CHANGE_PASSWORD_START'
export const AUTH_CHANGE_PASSWORD_SUCCESS = 'AUTH_CHANGE_PASSWORD_SUCCESS'
export const AUTH_CHANGE_PASSWORD_FAIL = 'AUTH_CHANGE_PASSWORD_FAIL'

export const AUTH_UPDATE_PROFILE_START = 'AUTH_UPDATE_PROFILE_START'
export const AUTH_UPDATE_PROFILE_SUCCESS = 'AUTH_UPDATE_PROFILE_SUCCESS'
export const AUTH_UPDATE_PROFILE_FAIL = 'AUTH_UPDATE_PROFILE_FAIL'

export const AUTH_UPLOAD_IMAGE_START = 'AUTH_UPLOAD_IMAGE_START'
export const AUTH_UPLOAD_IMAGE_SUCCESS = 'AUTH_UPLOAD_IMAGE_SUCCESS'
export const AUTH_UPLOAD_IMAGE_FAIL = 'AUTH_UPLOAD_IMAGE_FAIL'

export const AUTH_UPLOAD_IMAGE_CLEAR = 'AUTH_UPLOAD_IMAGE_CLEAR'

export const AUTH_STORE_TOKEN_FIREBASE_START = 'AUTH_STORE_TOKEN_FIREBASE_START'
export const AUTH_STORE_TOKEN_FIREBASE_SUCCESS = 'AUTH_STORE_TOKEN_FIREBASE_SUCCESS'
export const AUTH_STORE_TOKEN_FIREBASE_FAIL = 'AUTH_STORE_TOKEN_FIREBASE_FAIL'

export const AUTH_DELETE_TOKEN_FIREBASE_START = 'AUTH_DELETE_TOKEN_FIREBASE_START'
export const AUTH_DELETE_TOKEN_FIREBASE_SUCCESS = 'AUTH_DELETE_TOKEN_FIREBASE_SUCCESS'
export const AUTH_DELETE_TOKEN_FIREBASE_FAIL = 'AUTH_DELETE_TOKEN_FIREBASE_FAIL'

export const SET_AUTH_REDIRECT_PATH = 'SET_AUTH_REDIRECT_PATH'

export const SET_ALERT = 'SET_ALERT'
export const REMOVE_ALERT = 'REMOVE_ALERT'

export const SET_DIALOG_BOX = 'SET_DIALOG_BOX'
export const REMOVE_DIALOG_BOX = 'REMOVE_DIALOG_BOX'

export const FETCH_BANK_START = 'FETCH_BANK_START'
export const FETCH_BANK_SUCCESS = 'FETCH_BANK_SUCCESS'
export const FETCH_BANK_FAIL = 'FETCH_BANK_FAIL'

export const STORE_JURY_START = 'STORE_JURY_START'
export const STORE_JURY_SUCCESS = 'STORE_JURY_SUCCESS'
export const STORE_JURY_FAIL = 'STORE_JURY_FAIL'

export const UPDATE_JURY_START = 'UPDATE_JURY_START'
export const UPDATE_JURY_SUCCESS = 'UPDATE_JURY_SUCCESS'
export const UPDATE_JURY_FAIL = 'UPDATE_JURY_FAIL'

export const DELETE_JURY_START = 'DELETE_JURY_START'
export const DELETE_JURY_SUCCESS = 'DELETE_JURY_SUCCESS'
export const DELETE_JURY_FAIL = 'DELETE_JURY_FAIL'

export const DASHBOARD_START = 'DASHBOARD_START'
export const DASHBOARD_SUCCESS = 'DASHBOARD_SUCCESS'
export const DASHBOARD_FAIL = 'DASHBOARD_FAIL'

export const FETCH_ASET_WAKAF_START = 'FETCH_ASET_WAKAF_START'
export const FETCH_ASET_WAKAF_SUCCESS = 'FETCH_ASET_WAKAF_SUCCESS'
export const FETCH_ASET_WAKAF_FAIL = 'FETCH_ASET_WAKAF_FAIL'

export const STORE_ASET_WAKAF_START = 'STORE_ASET_WAKAF_START'
export const STORE_ASET_WAKAF_SUCCESS = 'STORE_ASET_WAKAF_SUCCESS'
export const STORE_ASET_WAKAF_FAIL = 'STORE_ASET_WAKAF_FAIL'

export const UPDATE_ASET_WAKAF_START = 'UPDATE_ASET_WAKAF_START'
export const UPDATE_ASET_WAKAF_SUCCESS = 'UPDATE_ASET_WAKAF_SUCCESS'
export const UPDATE_ASET_WAKAF_FAIL = 'UPDATE_ASET_WAKAF_FAIL'

export const DELETE_ASET_WAKAF_START = 'DELETE_ASET_WAKAF_START'
export const DELETE_ASET_WAKAF_SUCCESS = 'DELETE_ASET_WAKAF_SUCCESS'
export const DELETE_ASET_WAKAF_FAIL = 'DELETE_ASET_WAKAF_FAIL'

export const UPLOAD_IMAGES_ASSETS_WAKAF_START = 'UPLOAD_IMAGES_ASSETS_WAKAF_START'
export const UPLOAD_IMAGES_ASSETS_WAKAF_SUCCESS = 'UPLOAD_IMAGES_ASSETS_WAKAF_SUCCESS'
export const UPLOAD_IMAGES_ASSETS_WAKAF_FAIL = 'UPLOAD_IMAGES_ASSETS_WAKAF_FAIL'

export const DELETE_IMAGES_ASSETS_WAKAF_REDUX = 'DELETE_IMAGES_ASSETS_WAKAF_REDUX'

export const UPLOAD_DOCUMENTS_ASSETS_WAKAF_START = 'UPLOAD_DOCUMENTS_ASSETS_WAKAF_START'
export const UPLOAD_DOCUMENTS_ASSETS_WAKAF_SUCCESS = 'UPLOAD_DOCUMENTS_ASSETS_WAKAF_SUCCESS'
export const UPLOAD_DOCUMENTS_ASSETS_WAKAF_FAIL = 'UPLOAD_DOCUMENTS_ASSETS_WAKAF_FAIL'

export const DELETE_DOCUMENTS_ASSETS_WAKAF_REDUX = 'DELETE_DOCUMENTS_ASSETS_WAKAF_REDUX'

export const CLEAR_IMAGES_DOCUMENTS_ASET_WAKAF = 'CLEAR_IMAGES_DOCUMENTS_ASET_WAKAF'
export const CLEAR_ERROR_FORM_ASSET = 'CLEAR_ERROR_FORM_ASSET'

export const DELETE_IMAGE_ASSET_WAKAF_START = 'DELETE_IMAGE_ASSET_WAKAF_START'
export const DELETE_IMAGE_ASSET_WAKAF_SUCCESS = 'DELETE_IMAGE_ASSET_WAKAF_SUCCESS'
export const DELETE_IMAGE_ASSET_WAKAF_FAIL = 'DELETE_IMAGE_ASSET_WAKAF_FAIL'

export const DELETE_DOCUMENT_ASSET_WAKAF_START = 'DELETE_DOCUMENT_ASSET_WAKAF_START'
export const DELETE_DOCUMENT_ASSET_WAKAF_SUCCESS = 'DELETE_DOCUMENT_ASSET_WAKAF_SUCCESS'
export const DELETE_DOCUMENT_ASSET_WAKAF_FAIL = 'DELETE_DOCUMENT_ASSET_WAKAF_FAIL'

export const CLEAR_IMAGES_ASSET_WAKAF = 'CLEAR_IMAGES_ASSET_WAKAF'
export const CLEAR_DOCUMENTS_ASSET_WAKAF = 'CLEAR_DOCUMENTS_ASSET_WAKAF'

export const FETCH_IMAGE_ASSET_WAKAF_START = 'FETCH_IMAGE_ASSET_WAKAF_START'
export const FETCH_IMAGE_ASSET_WAKAF_SUCCESS = 'FETCH_IMAGE_ASSET_WAKAF_SUCCESS'
export const FETCH_IMAGE_ASSET_WAKAF_FAIL = 'FETCH_IMAGE_ASSET_WAKAF_FAIL'

export const FETCH_DOCUMENT_ASSET_WAKAF_START = 'FETCH_DOCUMENT_ASSET_WAKAF_START'
export const FETCH_DOCUMENT_ASSET_WAKAF_SUCCESS = 'FETCH_DOCUMENT_ASSET_WAKAF_SUCCESS'
export const FETCH_DOCUMENT_ASSET_WAKAF_FAIL = 'FETCH_DOCUMENT_ASSET_WAKAF_FAIL'

export const ADD_IMAGE_ASSEET_WAKAF_START = 'ADD_IMAGE_ASSET_WAKAF_START'
export const ADD_IMAGE_ASSEET_WAKAF_SUCCESS = 'ADD_IMAGE_ASSET_WAKAF_SUCCESS'
export const ADD_IMAGE_ASSEET_WAKAF_FAIL = 'ADD_IMAGE_ASSET_WAKAF_FAIL'

export const ADD_DOCUMENT_ASSEET_WAKAF_START = 'ADD_DOCUMENT_ASSET_WAKAF_START'
export const ADD_DOCUMENT_ASSEET_WAKAF_SUCCESS = 'ADD_DOCUMENT_ASSET_WAKAF_SUCCESS'
export const ADD_DOCUMENT_ASSEET_WAKAF_FAIL = 'ADD_DOCUMENT_ASSET_WAKAF_FAIL'

export const SEARCH_ASET_WAKAF_START = 'SEARCH_ASET_WAKAF_START'
export const SEARCH_ASET_WAKAF_SUCCESS = 'SEARCH_ASET_WAKAF_SUCCESS'
export const SEARCH_ASET_WAKAF_FAIL = 'SEARCH_ASET_WAKAF_FAIL'

export const FETCH_ASET_DETAIL_START = 'FETCH_ASET_DETAIL_START'
export const FETCH_ASET_DETAIL_SUCCESS = 'FETCH_ASET_DETAIL_SUCCESS'
export const FETCH_ASET_DETAIL_FAIL = 'FETCH_ASET_DETAIL_FAIL'

export const FETCH_TYPE_ASSET_START = 'FETCH_TYPE_ASSET_START'
export const FETCH_TYPE_ASSET_SUCCESS = 'FETCH_TYPE_ASSET_SUCCESS'
export const FETCH_TYPE_ASSET_FAIL = 'FETCH_TYPE_ASSET_FAIL'

export const FETCH_CATEGORY_START = 'FETCH_CATEGORY_START'
export const FETCH_CATEGORY_SUCCESS = 'FETCH_CATEGORY_SUCCESS'
export const FETCH_CATEGORY_FAIL = 'FETCH_TYPE_CATEGORY_FAIL'

export const FETCH_ASSET_DEPRECIATION_START = 'FETCH_ASSET_DEPRECIATION_START'
export const FETCH_ASSET_DEPRECIATION_SUCCESS = 'FETCH_ASSET_DEPRECIATION_SUCCESS'
export const FETCH_ASSET_DEPRECIATION_FAIL = 'FETCH_ASSET_DEPRECIATION_FAIL'

export const SET_MODAL = 'SET_MODAL'
export const REMOVE_MODAL = 'REMOVE_MODAL'

//MANAJEMEN ASET
export const STORE_GIVE_ASSET_START = 'STORE_GIVE_ASSET_START'
export const STORE_GIVE_ASSET_SUCCESS = 'STORE_GIVE_ASSET_SUCCESS'
export const STORE_GIVE_ASSET_FAIL = 'STORE_GIVE_ASSET_FAIL'

export const UPDATE_GIVE_ASSET_START = 'UPDATE_GIVE_ASSET_START'
export const UPDATE_GIVE_ASSET_SUCCESS = 'UPDATE_GIVE_ASSET_SUCCESS'
export const UPDATE_GIVE_ASSET_FAIL = 'UPDATE_GIVE_ASSET_FAIL'

export const DOWNLOAD_ASSET_START = 'DOWNLOAD_ASSET_START'
export const DOWNLOAD_ASSET_SUCCESS = 'DOWNLOAD_ASSET_SUCCESS'
export const DOWNLOAD_ASSET_FAIL = 'DOWNLOAD_ASSET_FAIL'

export const FETCH_LIST_REPORTS_START = 'FETCH_LIST_REPORTS_START'
export const FETCH_LIST_REPORTS_SUCCESS = 'FETCH_LIST_REPORTS_SUCCESS'
export const FETCH_LIST_REPORTS_FAIL = 'FETCH_LIST_REPORTS_FAIL'

export const STORE_STATUS_ASSET_START = 'STORE_STATUS_ASSET_START'
export const STORE_STATUS_ASSET_SUCCESS = 'STORE_STATUS_ASSET_SUCCESS'
export const STORE_STATUS_ASSET_FAIL = 'STORE_STATUS_ASSET_FAIL'

export const UPDATE_STATUS_ASSET_START = 'UPDATE_STATUS_ASSET_START'
export const UPDATE_STATUS_ASSET_SUCCESS = 'UPDATE_STATUS_ASSET_SUCCESS'
export const UPDATE_STATUS_ASSET_FAIL = 'UPDATE_STATUS_ASSET_FAIL'

export const STORE_REMINDER_ASSET_START = 'STORE_REMINDER_ASSET_START'
export const STORE_REMINDER_ASSET_SUCCESS = 'STORE_REMINDER_ASSET_SUCCESS'
export const STORE_REMINDER_ASSET_FAIL = 'STORE_REMINDER_ASSET_FAIL'

export const UPDATE_REMINDER_ASSET_START = 'UPDATE_REMINDER_ASSET_START'
export const UPDATE_REMINDER_ASSET_SUCCESS = 'UPDATE_REMINDER_ASSET_SUCCESS'
export const UPDATE_REMINDER_ASSET_FAIL = 'UPDATE_REMINDER_ASSET_FAIL'

export const DELETE_REMINDER_ASSET_START = 'DELETE_REMINDER_ASSET_START'
export const DELETE_REMINDER_ASSET_SUCCESS = 'DELETE_REMINDER_ASSET_SUCCESS'
export const DELETE_REMINDER_ASSET_FAIL = 'DELETE_REMINDER_ASSET_FAIL'

export const CLEAR_ERROR_REMINDER = 'CLEAR_ERROR_REMINDER'
export const CLEAR_ERROR = 'CLEAR_ERROR'

export const STORE_CATEGORY_START = 'STORE_CATEGORY_START'
export const STORE_CATEGORY_SUCCESS = 'STORE_CATEGORY_SUCCESS'
export const STORE_CATEGORY_FAIL = 'STORE_CATEGORY_FAIL'

export const UPDATE_CATEGORY_START = 'UPDATE_CATEGORY_START'
export const UPDATE_CATEGORY_SUCCESS = 'UPDATE_CATEGORY_SUCCESS'
export const UPDATE_CATEGORY_FAIL = 'UPDATE_CATEGORY_FAIL'

export const CLEAR_ERROR_CATEGORY = 'CLEAR_ERROR_CATEGORY'

export const STORE_TYPE_ASSET_START = 'STORE_TYPE_ASSET_START'
export const STORE_TYPE_ASSET_SUCCESS = 'STORE_TYPE_ASSET_SUCCESS'
export const STORE_TYPE_ASSET_FAIL = 'STORE_TYPE_ASSET_FAIL'

export const UPDATE_TYPE_ASSET_START = 'UPDATE_TYPE_ASSET_START'
export const UPDATE_TYPE_ASSET_SUCCESS = 'UPDATE_TYPE_ASSET_SUCCESS'
export const UPDATE_TYPE_ASSET_FAIL = 'UPDATE_TYPE_ASSET_FAIL'

export const CLEAR_ERROR_TYPE_ASSET = 'CLEAR_ERROR_TYPE_ASSET'

export const FETCH_NOTIFICATIONS_START = 'FETCH_NOTIFICATIONS_START'
export const FETCH_NOTIFICATIONS_SUCCESS = 'FETCH_NOTIFICATIONS_SUCCESS'
export const FETCH_NOTIFICATIONS_FAIL = 'FETCH_NOTIFICATIONS_FAIL'

export const READ_NOTIFICATIONS_START = 'READ_NOTIFICATIONS_START'
export const READ_NOTIFICATIONS_SUCCESS = 'READ_NOTIFICATIONS_SUCCESS'
export const READ_NOTIFICATIONS_FAIL = 'READ_NOTIFICATIONS_FAIL'

export const DOWNLOAD_LAPORAN_ASSETS_START = 'DOWNLOAD_LAPORAN_ASSETS_START'
export const DOWNLOAD_LAPORAN_ASSETS_SUCCESS = 'DOWNLOAD_LAPORAN_ASSETS_SUCCESS'
export const DOWNLOAD_LAPORAN_ASSETS_FAIL = 'DOWNLOAD_LAPORAN_ASSETS_FAIL'

export const DOWNLOAD_LAPORAN_QR_ASSETS_START = 'DOWNLOAD_LAPORAN_QR_ASSETS_START'
export const DOWNLOAD_LAPORAN_QR_ASSETS_SUCCESS = 'DOWNLOAD_LAPORAN_QR_ASSETS_SUCCESS'
export const DOWNLOAD_LAPORAN_QR_ASSETS_FAIL = 'DOWNLOAD_LAPORAN_QR_ASSETS_FAIL'

export const FETCH_ENTITY_START = 'FETCH_ENTITY_START'
export const FETCH_ENTITY_SUCCESS = 'FETCH_ENTITY_SUCCESS'
export const FETCH_ENTITY_FAIL = 'FETCH_ENTITY_FAIL'

export const STORE_ENTITY_START = 'STORE_ENTITY_START'
export const STORE_ENTITY_SUCCESS = 'STORE_ENTITY_SUCCESS'
export const STORE_ENTITY_FAIL = 'STORE_ENTITY_FAIL'

export const UPDATE_ENTITY_START = 'UPDATE_ENTITY_START'
export const UPDATE_ENTITY_SUCCESS = 'UPDATE_ENTITY_SUCCESS'
export const UPDATE_ENTITY_FAIL = 'UPDATE_ENTITY_FAIL'

export const CLEAR_ERROR_ENTITY = 'CLEAR_ERROR_ENTITY'

export const FETCH_DIVISION_START = 'FETCH_DIVISION_START'
export const FETCH_DIVISION_SUCCESS = 'FETCH_DIVISION_SUCCESS'
export const FETCH_DIVISION_FAIL = 'FETCH_DIVISION_FAIL'

export const STORE_DIVISION_START = 'STORE_DIVISION_START'
export const STORE_DIVISION_SUCCESS = 'STORE_DIVISION_SUCCESS'
export const STORE_DIVISION_FAIL = 'STORE_DIVISION_FAIL'

export const UPDATE_DIVISION_START = 'UPDATE_DIVISION_START'
export const UPDATE_DIVISION_SUCCESS = 'UPDATE_DIVISION_SUCCESS'
export const UPDATE_DIVISION_FAIL = 'UPDATE_DIVISION_FAIL'

export const CLEAR_ERROR_DIVISION = 'CLEAR_ERROR_DIVISION'

export const FETCH_BRANDS_START = 'FETCH_BRANDS_START'
export const FETCH_BRANDS_SUCCESS = 'FETCH_BRANDS_SUCCESS'
export const FETCH_BRANDS_FAIL = 'FETCH_BRANDS_FAIL'

export const STORE_BRAND_START = 'STORE_BRAND_START'
export const STORE_BRAND_SUCCESS = 'STORE_BRAND_SUCCESS'
export const STORE_BRAND_FAIL = 'STORE_BRAND_FAIL'

export const UPDATE_BRAND_START = 'UPDATE_BRAND_START'
export const UPDATE_BRAND_SUCCESS = 'UPDATE_BRAND_SUCCESS'
export const UPDATE_BRAND_FAIL = 'UPDATE_BRAND_FAIL'

export const CLEAR_ERROR_BRAND = 'CLEAR_ERROR_BRAND'