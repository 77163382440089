import * as actionTypes from '../actions/actionTypes'
import { updateObject } from '../../shared/utility'

const initialState = {
  divisions: {},
  changing: 0,

  loading: false,
  error: {},
}

const fetchDivisionStart = (state, action) => {
  return updateObject(state, { loading: true })
}

const fetchDivisionSuccess = (state, action) => {
  return updateObject(state, {
    divisions: action.divisions,
    loading: false
  })
}

const fetchDivisionFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false
  })
}

const storeDivisionStart = (state, action) => {
  return updateObject(state, { loading: true })
}

const storeDivisionSuccess = (state, action) => {
  return updateObject(state, {
    changing: state.changing+1,
    loading: false
  })
}

const storeDivisionFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false
  })
}

const updateDivisionStart = (state, action) => {
  return updateObject(state, { loading: true })
}

const updateDivisionSuccess = (state, action) => {
  return updateObject(state, {
    changing: state.changing+1,
    loading: false
  })
}

const updateDivisionFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false
  })
}

const clearErrorDivision = (state, action) => {
  return updateObject(state, {
    loading: false,
    error: {}
  })
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_DIVISION_START: return fetchDivisionStart(state, action)
    case actionTypes.FETCH_DIVISION_SUCCESS: return fetchDivisionSuccess(state, action)
    case actionTypes.FETCH_DIVISION_FAIL: return fetchDivisionFail(state, action)
    case actionTypes.STORE_DIVISION_START: return storeDivisionStart(state, action)
    case actionTypes.STORE_DIVISION_SUCCESS: return storeDivisionSuccess(state, action)
    case actionTypes.STORE_DIVISION_FAIL: return storeDivisionFail(state, action)
    case actionTypes.UPDATE_DIVISION_START: return updateDivisionStart(state, action)
    case actionTypes.UPDATE_DIVISION_SUCCESS: return updateDivisionSuccess(state, action)
    case actionTypes.UPDATE_DIVISION_FAIL: return updateDivisionFail(state, action)
    case actionTypes.CLEAR_ERROR_DIVISION: return clearErrorDivision(state, action)

    default: return state
  }
}

export default reducer