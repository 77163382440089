import React, { Fragment, useEffect, useState } from 'react';
import { connect } from 'react-redux';

import { Button, FormControl, FormHelperText, Grid, IconButton, InputLabel, makeStyles, Paper, Select,
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography, 
  InputAdornment, Card, CardActionArea, CardMedia, CardActions, CardContent, Tooltip } from '@material-ui/core'
import { Delete as DeleteIcon, Search as DetailIcon } from '@material-ui/icons';

import NumberFormat from 'react-number-format';

// import Modal from '../../components/UI/Modal/Modal'
import Dropzone from '../../../UI/Dropzone/Dropzone'
import Loading from '../../../UI/Loading/CircularProgress/CircularProgress'

import * as actions from '../../../../store/actions/index';

import * as yup from 'yup'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers'

import moment from 'moment';
import { DatePicker } from '@material-ui/pickers';
import imageCompression from "browser-image-compression";
// import palette from '../../theme/palette';

const useStyles = makeStyles(theme => ({
  row: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  formRoot: {
    // border: '1px groove #000',
    borderRadius: 8,
    boxShadow: theme.shadows[5],
    marginBottom: theme.spacing(2),
    padding: theme.spacing(2, 4, 3)
  },
  imageList: {
    maxWidth: 345,
  },
  media: {
    height: 140,
  },
  buttonDelete: {
    backgroundColor: 'rgb(220, 0, 78)',
    '&:hover': {
      backgroundColor: 'rgb(200, 0, 58)'
    },
    color: '#FFFFFF'
  }
}))

const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

const schema = yup.object().shape({
  value: yup.string().required(),
  year: yup.number().required(),
  wakifName: yup.string().required('Nama wajib diisi'),
  wakifAddress: yup.string().required('Alamat wajib diisi'),
  wakifPhone: yup.string().required('No Telepon wajib diisi')
  .matches(phoneRegExp, 'Phone number is not valid'),
  id_entity: yup.string().required('Entitas wajib diisi'),
})

const AddAsetWakaf = props => {
  const {
    onStoreAsset,onFetchCategory,onFetchTypeAsset,onUploadDocument,onUploadImage,onDeleteDocumentRedux,onAlert,typeAssets,categories,
    onDeleteImageRedux,loading,loadingUploadDocument,loadingUploadImage,errorForm,token,assetImageUrl,assetDocumentUrl,
    onClearErrorFormAsset,onClearImagesDocuments,onFetchEntity,entities,onFetchBrand,brands
  } = props;

  const classes = useStyles();

  useEffect(() => {
    onFetchCategory(token)
    onFetchTypeAsset(token)
    onFetchEntity(token)
    onClearErrorFormAsset()
    onClearImagesDocuments()
    onFetchBrand(token)
  }, [onFetchCategory,onFetchTypeAsset,onFetchEntity,token,onClearErrorFormAsset,onClearImagesDocuments,onFetchBrand])

  let optionType = <Loading />;
  let optionCategories = <Loading />;
  let optionEntities = <Loading />;
  let optionBrands = <Loading />;
  let button = (loading ? <Loading /> :
    assetImageUrl.length > 0 ? (
    <Button type="submit" variant="contained" style={{ backgroundColor: '#FF9300', color: '#FFFFFF' }} fullWidth disableElevation>
      Simpan
    </Button>
  )
  : (
    <Button variant="contained" disabled  fullWidth disableElevation>
      Simpan
    </Button>)
  )

  const { register, handleSubmit, errors } = useForm({
    resolver: yupResolver(schema)
  })

  const onSubmit = data => {
    data.value = +(data.value).replace(/[Rp.]/g, '');
    data.wakif = [
      {
          name: data.wakifName,
          address: data.wakifAddress,
          phone: data.wakifPhone,
          id_entity: data.id_entity
      }
    ];
    
    data.image = assetImageUrl;
    data.document = assetDocumentUrl;
    data.received_date = moment.utc(moment(inputDate).toDate()).format('YYYY-MM-DD HH:mm:ss');
    
    // if(data.received_date){
    //   const jam_utc = moment.utc().format('HH:mm:ss');
    //   data.received_date = moment.utc(data.received_date+' '+jam_utc).format('YYYY-MM-DD HH:mm:ss');
    // }

    onStoreAsset(data, token);
  }

  function isEmpty(obj) {
    for(var key in obj) {
        if(obj.hasOwnProperty(key))
            return false;
    }
    return true;
  }

  if (!isEmpty(typeAssets)) {
    optionType = (
      <Fragment>
        {typeAssets.map((type) => (        
          <option key={type.id_type} value={type.id_type}>{type.name}</option>
        ))}
      </Fragment>
    )
  }

  if (!isEmpty(categories)) {
    optionCategories = (
      <Fragment>
        {categories.map((category) => (        
          <option key={category.id_cat} value={category.id_cat}>{category.name}</option>
        ))}
      </Fragment>
    )
  }

  if (!isEmpty(categories)) {
    optionCategories = (
      <Fragment>
        {categories.map((category) => (
          <option key={category.id_cat} value={category.id_cat}>{category.name}</option>
        ))}
      </Fragment>
    )
  }

  if (!isEmpty(entities)) {
    optionEntities = (
      <Fragment>
        {entities.map((entity) => (
          <option key={entity.id_entity} value={entity.id_entity}>{entity.name}</option>
        ))}
      </Fragment>
    )
  }

  if (!isEmpty(brands)) {
    optionBrands = (
      <Fragment>
        {brands.map((brand) => (
          <option key={brand.id_brand} value={brand.id_brand}>{brand.name}</option>
        ))}
      </Fragment>
    )
  }

  let loadingUploadImages = null;
  if (loadingUploadImage) {
    loadingUploadImages = <Loading />
  }
  let loadingUploadDocuments = null;
  if (loadingUploadDocument) {
    loadingUploadDocuments = <Loading />
  }

  const [image, setImage] = useState('');
  const [doc, setDoc] = useState('');
  // const [base64, setB64] = useState();

  const handleChangeBanner = event => {
    setImage(event)
    const imageFile = event[0];
    const options = {
      maxSizeMB: 4,
      maxWidthOrHeight: 512,
      useWebWorker: true
    }
    imageCompression(imageFile, options)
      .then(function (compressedFile) {
        let fileUpload = new File(
          [compressedFile],
          compressedFile.name,
          {
            type: compressedFile.type,
            path: compressedFile.name
          })
        // console.log(fileUpload);
        return onUploadImage(fileUpload, token); // write your own logic
      })
      .catch(function (error) {
        onAlert(error.message, 'error')
      });
    // if(event[0].size < 5000000){
    //   onUploadImage(event, token);
    // }else{
    //   onAlert('maksimal file 4 mb', 'error')
    // }
  }

  const handleChangeDocument = event => {
    setDoc(event)
    if(event[0].size < 5000000){
      onUploadDocument(event, token)
    }else{
      onAlert('maksimal file 4 mb', 'error')
    }
  }


  let imageUploadSukes = null;

  if (!isEmpty(assetImageUrl)) {
    // console.log(assetImageUrl);
    imageUploadSukes = (
      <Fragment>
          {assetImageUrl.map((image, index) => (
            <Grid key={index} item lg={3} md={3} sm={12} xs={12}>
              <Card className={classes.imageList}>
                <CardActionArea>
                  <CardMedia
                    className={classes.media}
                    image={image.url}
                    title="Contemplative Reptile"
                  />
                </CardActionArea>
                <CardContent>
                  <Typography variant="h5">
                    {image.caption}
                  </Typography>
                </CardContent>
                <CardActions>
                  <Button
                    variant="contained"
                    color="secondary"
                    className={classes.buttonDelete}
                    startIcon={<DeleteIcon />}
                    onClick={() => onDeleteImageRedux(index)}
                    fullWidth
                  >
                    Delete
                  </Button>
                  {/* <Button size="small" color="primary" onClick={() => onDeleteImageRedux(index)}>
                    delete
                  </Button> */}
                </CardActions>
              </Card>
            </Grid>
          ))}
      </Fragment>
    )
  }
  let documentUploadSukes = null;

  if (!isEmpty(assetDocumentUrl)) {
    // console.log(assetDocumentUrl);
    documentUploadSukes = (
      <Fragment>
        <Grid container spacing={2}>
          <TableContainer fullWidth component={Paper}>
            <Table aria-label="customized table">
              <TableHead>
                <TableRow>
                  <TableCell style={{ width: '5%' }}>No</TableCell>
                  <TableCell style={{ width: '55%' }}>Nama</TableCell>
                  <TableCell style={{ width: '40%' }}>Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {assetDocumentUrl.map((document, index) => (
                  <TableRow key={index}>
                    <TableCell style={{ width: '5%' }}>{index+1}</TableCell>
                    <TableCell style={{ width: '55%' }}>{document.caption}</TableCell>
                    <TableCell style={{ width: '40%' }}>
                      <Tooltip title="Detail Document">
                        <a href={document.url} target="blank">
                          <IconButton aria-label="detail">
                            <DetailIcon />
                          </IconButton>
                        </a>  
                      </Tooltip>
                      <Tooltip title="Delete Document">
                        <IconButton aria-label="detail" onClick={() => onDeleteDocumentRedux(index)}>
                          <DeleteIcon />
                        </IconButton>
                      </Tooltip>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Fragment>
    )
  }

  const [formState, setFormState] = useState({
    name: '',
    value: '',
    year: '',
    received_date: '',
    id_cat: '',
    id_type: '',
    id_entity: '',
    description: '',
    wakifName: '',
    wakifPhone: '',
    wakifAddress: '',
    id_wakif: '',
    id_brand: '',
    kind: ''
  })

  const handleChange = (event) => {
    // console.log(event.target.value);
    const target = event.target.name
    event.persist()

    setFormState((formState) => ({
      ...formState,
      [target]: event.target.value
    }))
  }

  const [inputDate, setInputDate] = useState(Date());
  const handleDateChange = event => {
    setInputDate(moment(event));
  }

  return (
    <Fragment>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={2}>
          <Grid item lg={8} md={8} sm={12} xs={12}>
            <Paper className={classes.formRoot}>
              <Typography>Data Aset*</Typography>
              <div className={classes.row}>

                <Grid container spacing={2}>
                  <Grid item lg={6} md={6} sm={12} xs={12}>
                    <FormControl
                      error={errorForm.id_type ? true : false}
                      variant="outlined" className={classes.formControl} fullWidth>
                      <InputLabel htmlFor="outlined-age-native-simple">Jenis</InputLabel>
                      <Select
                        native
                        defaultValue={formState.id_type}
                        onChange={handleChange}
                        label="Jenis"
                        inputProps={{
                          name: 'id_type',
                          id: 'outlined-age-native-simple'
                        }}
                        name="id_type"
                        inputRef={register}
                      >
                        <option aria-label="None" value="" />
                        {optionType}
                      </Select>
                      <FormHelperText>{errorForm.id_type && errorForm.id_type[0]}</FormHelperText>
                    </FormControl>
                  </Grid>

                  <Grid item lg={6} md={6} sm={12} xs={12}>
                    <FormControl
                      error={errorForm.id_cat ? true : false}
                      variant="outlined" className={classes.formControl} fullWidth>
                      <InputLabel htmlFor="outlined-age-native-simple">Kategori</InputLabel>
                      <Select
                        native
                        defaultValue={formState.id_cat}
                        onChange={handleChange}
                        label="Kategori"
                        inputProps={{
                          name: 'id_cat',
                          id: 'outlined-age-native-simple'
                        }}
                        name="id_cat"
                        inputRef={register}
                      >
                        <option aria-label="None" value="" />
                        {optionCategories}
                      </Select>
                      <FormHelperText>{errorForm.id_cat && errorForm.id_cat[0]}</FormHelperText>
                    </FormControl>
                  </Grid>
                </Grid>

                <Grid container spacing={2}>
                  <Grid item lg={6} md={6} sm={12} xs={12}>
                    <FormControl
                      error={errorForm.id_brand ? true : false}
                      variant="outlined" className={classes.formControl} fullWidth>
                      <InputLabel htmlFor="outlined-age-native-simple">Merek</InputLabel>
                      <Select
                        native
                        defaultValue={formState.id_brand}
                        onChange={handleChange}
                        label="Merek"
                        inputProps={{
                          name: 'id_brand',
                          id: 'outlined-age-native-simple'
                        }}
                        name="id_brand"
                        inputRef={register}
                      >
                        <option aria-label="None" value="" />
                        {optionBrands}
                      </Select>
                      <FormHelperText>{errorForm.id_brand && errorForm.id_brand[0]}</FormHelperText>
                    </FormControl>
                  </Grid>

                  <Grid item lg={6} md={6} sm={12} xs={12}>
                    <TextField
                      label="Tipe"
                      variant="outlined"
                      name="kind"
                      fullWidth
                      inputRef={register}
                      error={errorForm.kind ? true : false}
                      helperText={errorForm.kind && errorForm.kind[0]}
                      defaultValue={formState.kind}
                      onChange={handleChange}
                    />
                  </Grid>
                </Grid>
                
                <Grid container spacing={2}>
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <TextField
                      label="Nama Aset "
                      variant="outlined"
                      name="name"
                      fullWidth
                      inputRef={register}
                      error={errorForm.name ? true : false}
                      helperText={errorForm.name && errorForm.name[0]}
                      defaultValue={formState.name}
                      onChange={handleChange}
                    />
                  </Grid>
                </Grid>

                <Grid container spacing={2}>
                  <Grid item lg={6} md={6} sm={12} xs={12}>
                    {/* <TextField
                      label="Tanggal Diterima"
                      variant="outlined"
                      type="date"
                      name="received_date"
                      inputRef={register}
                      error={errorForm.received_date ? true : false}
                      helperText={errorForm.received_date && errorForm.received_date[0]}
                      fullWidth
                      defaultValue={formState.received_date}
                      onChange={handleChange}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    /> */}
                    <DatePicker
                      variant="dialog"
                      format="DD/MM/yyyy"
                      views={["year", "month", "date"]}
                      label="Tanggal Diterima"
                      helperText="Start from year selection"
                      disableFuture
                      value={inputDate}
                      onChange={handleDateChange}
                      fullWidth
                      name="date_input"
                      inputRef={register}
                    />
                  </Grid>
                  <Grid item lg={6} md={6} sm={12} xs={12}>
                    <FormControl
                      error={errors.value ? true : false}
                      variant="outlined" className={classes.formControl} fullWidth>
                      <NumberFormat 
                        style={{ padding: '18.5px 14px', width: '90%' }}
                        allowLeadingZeros={false}
                        thousandSeparator="." 
                        decimalSeparator={','} 
                        prefix={'Rp.'} 
                        name="value" 
                        getInputRef={register} 
                        isNumericString={true} 
                        defaultValue={formState.value} 
                        onChange={handleChange}
                        placeholder="Perolehan Aset"
                      />  
                      <FormHelperText>{errors.value && errors.value.message}</FormHelperText>
                    </FormControl>
                  </Grid>
                </Grid>

                <Grid container spacing={2}>
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <TextField
                      label="Deskripsi"
                      variant="outlined"
                      name="description"
                      multiline
                      rows={4}
                      inputRef={register}
                      error={errorForm.description ? true : false}
                      helperText={errorForm.description && errorForm.description[0]}
                      fullWidth
                      defaultValue={formState.description}
                      onChange={handleChange}
                    />
                  </Grid>
                </Grid>
              </div>
            </Paper>

            <Paper className={classes.formRoot}>

              <div className={classes.row}>
                <Grid container spacing={2}>
                  <Grid item lg={6} md={6} sm={12} xs={12}>
                    <Typography>Pemilik Aset*</Typography>
                  </Grid>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item lg={6} md={6} sm={12} xs={12}>
                    <TextField
                      label="Nama"
                      variant="outlined"
                      // name="wakif.0.name"
                      name="wakifName"
                      inputRef={register}
                      error={errors.wakifName ? true : false}
                      helperText={errors.wakifName && errors.wakifName.message}
                      fullWidth
                      // defaultValue={formState.wakif[0].name}
                      defaultValue={formState.wakifName}
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item lg={6} md={6} sm={12} xs={12}>
                    <FormControl
                      error={errors.id_entity ? true : false}
                      variant="outlined" className={classes.formControl} fullWidth>
                      <InputLabel htmlFor="outlined-age-native-simple">Entitas</InputLabel>
                      <Select
                        native
                        defaultValue={formState.id_entity.value}
                        onChange={handleChange}
                        label="Entitas"
                        inputProps={{
                          name: 'id_entity',
                          id: 'outlined-age-native-simple'
                        }}
                        name="id_entity"
                        inputRef={register}
                      >
                        <option aria-label="None" value="" />
                        {optionEntities}
                      </Select>
                      <FormHelperText>{errors.id_entity && errors.id_entity.message}</FormHelperText>
                    </FormControl>
                  </Grid>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item lg={6} md={6} sm={12} xs={12}>
                    <TextField
                      label="Nomor Telepon"
                      variant="outlined"
                      // name="wakif.0.phone"
                      name="wakifPhone"
                      inputRef={register}
                      error={errors.wakifPhone ? true : false}
                      helperText={errors.wakifPhone && errors.wakifPhone.message}
                      fullWidth
                      // defaultValue={formState.wakif[0].phone}
                      defaultValue={formState.wakifPhone}
                      onChange={handleChange}
                    />
                  </Grid>
                </Grid>

                <Grid container spacing={2}>
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <TextField
                      label="Alamat"
                      variant="outlined"
                      // name="wakif.0.address"
                      name="wakifAddress"
                      multiline
                      rows={4}
                      inputRef={register}
                      error={errors.wakifAddress ? true : false}
                      helperText={errors.wakifAddress && errors.wakifAddress.message}
                      fullWidth
                      // defaultValue={formState.wakif[0].address}
                      defaultValue={formState.wakifAddress}
                      onChange={handleChange}
                    />
                  </Grid>
                </Grid>
              </div>
            </Paper>

            <Paper className={classes.formRoot}>
              <Typography>Foto*</Typography>
              <div className={classes.row}>

                <Grid container spacing={2}>
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <Dropzone multiple={false} fileType={'image/*'} value={doc} handleChangeBanner={handleChangeBanner} />
                  </Grid>
                  {/* {console.log(loadingUploadImage)} */}
                  {loadingUploadImages}
                </Grid>
                
                <Grid container spacing={2}>

                  <Grid item lg={12} md={12} sm={12} xs={12}>
                  <Fragment>
                    <Grid container spacing={2}>
                      {/* {imageAsetApi} */}
                      {imageUploadSukes}
                    </Grid>
                  </Fragment>
                  </Grid>
                </Grid>
              </div>
            </Paper>
          </Grid>

          <Grid item lg={4} md={4} sm={12} xs={12}>
            <Paper className={classes.formRoot}>
              <Typography>Penyusutan Aset*</Typography>
              <div className={classes.row}>

                <Grid container spacing={2}>
                  <Grid item lg={8} md={8} sm={8} xs={8}>
                    <TextField
                      label="Nilai Perolehan Aset"
                      id="outlined-start-adornment"
                      value={formState.value}
                      fullWidth
                      disabled
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item lg={4} md={4} sm={4} xs={4}>
                    <TextField
                      label=""
                      id="outlined-start-adornment"
                      defaultValue={formState.year}
                      onChange={handleChange}
                      name="year"
                      inputRef={register}
                      error={errors.year ? true : false}
                      helperText={errors.year && errors.year.message}
                      fullWidth
                      // className={clsx(classes.margin, classes.textField)}
                      InputProps={{
                        endAdornment: <InputAdornment position="end">tahun</InputAdornment>,
                      }}
                      variant="outlined"
                    />
                  </Grid>
                </Grid>
                
              </div>
            </Paper>

            <Paper className={classes.formRoot}>
              <Typography>File Document PDF(opsional)</Typography>
              <div className={classes.row}>

                <Grid container spacing={2}>
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <Dropzone multiple={false} fileType={'application/pdf'} value={image} handleChangeBanner={handleChangeDocument} />
                  </Grid>
                  {loadingUploadDocuments}
                </Grid>
              </div>
              <div className={classes.row}>
                <Grid container spacing={2}>
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                      {/* {documentAsetApi} */}
                      {documentUploadSukes}
                    </Grid>
                  </Grid>
              </div>
            </Paper>
          </Grid>
        </Grid>

        <Grid container spacing={2}>
          <Grid item lg={8} md={8} sm={12} xs={12}>
          </Grid>
          <Grid item lg={4} md={4} sm={12} xs={12}>
            <Grid container spacing={2}>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <Button type="submit" variant="contained" fullWidth onClick={props.closedModalDialog} disableElevation>
                  Batal
                </Button>
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                {button}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </form>
    </Fragment>
  )

}
  const mapStateToProps = state => {
    return {
      loading: state.asset.loading,
      loadingUploadImage: state.asset.loadingUploadImage,
      loadingUploadDocument: state.asset.loadingUploadDocument,
      token: state.auth.token,
      errorForm: state.asset.error,
      typeAssets: state.typeAsset.typeAssets,
      categories: state.category.categories,
      brands: state.brand.brands,
      entities: state.entity.entities,
      assetImageUrl: state.asset.urlImage,
      assetDocumentUrl: state.asset.urlDocument,
    }
  }

  const mapDispatchToProps = dispatch => {
    return {
      onFetchTypeAsset: (token) => dispatch(actions.fetchTypeAssets(token)),
      onFetchCategory: (token) => dispatch(actions.fetchCategory(token)),
      onFetchEntity: (token) => dispatch(actions.fetchEntity(token)),
      onFetchBrand: (token) => dispatch(actions.fetchBrands(token)),
      onStoreAsset: (storeData, token) => dispatch(actions.storeAsetWakaf(storeData, token)),
      onUploadImage: (data, token) => dispatch(actions.uploadImageAssetsWakaf(data, token)),
      onDeleteImageRedux: (index) => dispatch(actions.deleteImagesAssetsWakafRedux(index)),
      onUploadDocument: (data, token) => dispatch(actions.uploadDocumentAssetsWakaf(data, token)),
      onDeleteDocumentRedux: (index) => dispatch(actions.deleteDocumentAssetsWakafRedux(index)),
      onClearErrorFormAsset: () => dispatch(actions.clearErrorFormAsset()),
      onClearImagesDocuments: () => dispatch(actions.clearImagesDocumentsAsetWakaf()),
      onAlert: (msg, status) => dispatch(actions.setAlert(msg, status)),
    }
  }

export default connect(mapStateToProps, mapDispatchToProps)(AddAsetWakaf);