import * as actions from './actionTypes'
import axios from '../../axios-orders'
import { setAlert } from './alert'

export const fetchAssetDetailsStart = () => {
  return {
    type: actions.FETCH_ASET_DETAIL_START
  }
}

export const fetchAssetDetailsSuccess = (assets) => {
  return {
    type: actions.FETCH_ASET_DETAIL_SUCCESS,
    assets: assets
  }
}

export const fetchAssetDetailsFail = (error) => {
  return {
    type: actions.FETCH_ASET_DETAIL_FAIL,
    error: error
  }
}

export const fetchAssetDetails = (uuid, token) => {
  // const body = { uuid : uuid};
  return dispatch => {
    dispatch(fetchAssetDetailsStart())
    axios.get('assets?uuid=' + uuid, {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    })
      .then(res => {
        // console.log(res.data.data[res.data.total-1])
        dispatch(fetchAssetDetailsSuccess(res.data.data[res.data.total-1]))
      })
      .catch(err => {
        dispatch(fetchAssetDetailsFail(err))
      })
  }
}

export const storeGiveAssetStart = () => {
  return {
    type: actions.STORE_GIVE_ASSET_START
  }
}

export const storeGiveAssetSuccess = (assets) => {
  return {
    type: actions.STORE_GIVE_ASSET_SUCCESS,
    assets: assets
  }
}

export const storeGiveAssetFail = (error) => {
  return {
    type: actions.STORE_GIVE_ASSET_FAIL,
    error: error
  }
}

export const storeGiveAsset = (storeData, token) => {
  return dispatch => {
    dispatch(storeGiveAssetStart())
    axios.post('assets/history', storeData, {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    })
      .then(res => {
        dispatch(storeGiveAssetSuccess( res ))
        dispatch(setAlert(res.data.msg_str, 'success'))
      })
      .catch(err => {
        dispatch(storeGiveAssetFail(err.response.data.throwable))
      });
  }
}

export const updateGiveAssetStart = () => {
  return {
    type: actions.UPDATE_GIVE_ASSET_START
  }
}

export const updateGiveAssetSuccess = (response) => {
  return {
    type: actions.UPDATE_GIVE_ASSET_SUCCESS
  }
}

export const updateGiveAssetFail = (error) => {
  return {
    type: actions.UPDATE_GIVE_ASSET_FAIL,
    error: error
  }
}

export const updateGiveAsset = (id, storeData, token) => {
  return dispatch => {
    dispatch(updateGiveAssetStart());
    axios.put('assets/history/'+ id, storeData, {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    })
      .then(response => {
        dispatch(updateGiveAssetSuccess(response))
        dispatch(setAlert(response.data.msg_str, 'success'))
      })
      .catch(error => {
        dispatch(updateGiveAssetFail(error))
      })
  }
}

export const storeStatusAssetStart = () => {
  return {
    type: actions.STORE_STATUS_ASSET_START
  }
}

export const storeStatusAssetSuccess = (res) => {
  return {
    type: actions.STORE_STATUS_ASSET_SUCCESS
  }
}

export const storeStatusAssetFail = (error) => {
  return {
    type: actions.STORE_STATUS_ASSET_FAIL,
    error: error
  }
}

export const storeStatusAsset = (storeData, token, history) => {
  // const path = history.location.pathname;
  return dispatch => {
    dispatch(storeStatusAssetStart());
    axios.post('assets/status', storeData, {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    })
      .then(res => {
        console.log(res.data)
        dispatch(storeStatusAssetSuccess(res))
        dispatch(setAlert(res.data.msg_str, 'success'))
        // dispatch(history.push(path))
      })
      .catch(err => {
        dispatch(storeStatusAssetFail(err.response.data.throwable))
      })
  }
}

export const updateStatusAssetStart = () => {
  return {
    type: actions.UPDATE_STATUS_ASSET_START
  }
}

export const updateStatusAssetSuccess = (res) => {
  return {
    type: actions.UPDATE_STATUS_ASSET_SUCCESS
  }
}

export const updateStatusAssetFail = (error) => {
  return {
    type: actions.UPDATE_STATUS_ASSET_FAIL,
    error: error
  }
}

export const updateStatusAsset = (id, storeData, token, history) => {
  const path = history.location.pathname;
  // console.log(history)
  return dispatch => {
    dispatch(updateStatusAssetStart());
    axios.put('assets/status/' + id, storeData, {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    })
      .then(res => {
        console.log(res.data)
        dispatch(updateStatusAssetSuccess(res))
        dispatch(setAlert(res.data.msg_str, 'success'))
        dispatch(history.push(path))
      })
      .catch(err => {
        dispatch(updateStatusAssetFail(err))
        // dispatch(setAlert(err.response.data.msg_str, 'error'))
      })
  }
}

export const storeReminderAssetStart = () => {
  return {
    type: actions.STORE_REMINDER_ASSET_START
  }
}

export const storeReminderAssetSuccess = (res) => {
  return {
    type: actions.STORE_REMINDER_ASSET_SUCCESS
  }
}

export const storeReminderAssetFail = (error) => {
  return {
    type: actions.STORE_REMINDER_ASSET_FAIL,
    error: error
  }
}

export const storeReminderAsset = (storeData, token, history) => {
  // const path = history.location.pathname;
  return dispatch => {
    dispatch(storeReminderAssetStart());
    axios.post('reminder', storeData, {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    })
      .then(res => {
        console.log(res.data)
        dispatch(storeReminderAssetSuccess(res))
        dispatch(setAlert(res.data.msg_str, 'success'))
        // dispatch(history.push(path))
      })
      .catch(err => {
        dispatch(storeReminderAssetFail(err.response.data.throwable))
      })
  }
}

export const updateReminderAssetStart = () => {
  return {
    type: actions.STORE_REMINDER_ASSET_START
  }
}

export const updateReminderAssetSuccess = (res) => {
  return {
    type: actions.STORE_REMINDER_ASSET_SUCCESS
  }
}

export const updateReminderAssetFail = (error) => {
  return {
    type: actions.STORE_REMINDER_ASSET_FAIL,
    error: error
  }
}

export const updateReminderAsset = (id, storeData, token) => {
  return dispatch => {
    dispatch(updateReminderAssetStart());
    axios.put('reminder/' + id, storeData, {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    })
      .then(res => {
        dispatch(updateReminderAssetSuccess(res))
        dispatch(setAlert(res.data.msg_str, 'success'))
        // dispatch(history.push(path))
      })
      .catch(err => {
        dispatch(updateReminderAssetFail(err.response.data.throwable))
      })
  }
}

export const deleteReminderAssetStart = () => {
  return {
    type: actions.DELETE_REMINDER_ASSET_START
  }
}

export const deleteReminderAssetSuccess = (res) => {
  return {
    type: actions.DELETE_REMINDER_ASSET_SUCCESS
  }
}

export const deleteReminderAssetFail = (error) => {
  return {
    type: actions.DELETE_REMINDER_ASSET_FAIL,
    error: error
  }
}

export const deleteReminderAsset = (id, token) => {
  return dispatch => {
    dispatch(deleteReminderAssetStart());
    axios.delete('reminder/' + id, {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    })
      .then(res => {
        dispatch(deleteReminderAssetSuccess(res))
        dispatch(setAlert(res.data.msg_str, 'success'))
        // dispatch(history.push(path))
      })
      .catch(err => {
        dispatch(deleteReminderAssetFail(err.response.data.msg_str))
        dispatch(setAlert(err.response.data.msg_str, 'error'))
      })
  }
}

export const clearErrorReminder = () => {
  return {
    type: actions.CLEAR_ERROR_REMINDER
  }
}

export const clearError = () => {
  return {
    type: actions.CLEAR_ERROR
  }
}