import * as actions from './actionTypes'
import axios from '../../axios-orders'
import { setAlert } from './alert'

export const fetchBrandsStart = () => {
  return {
    type: actions.FETCH_BRANDS_START
  }
}

export const fetchBrandsSuccess = (brands) => {
  return {
    type: actions.FETCH_BRANDS_SUCCESS,
    brands: brands
  }
}

export const fetchBrandsFail = (error) => {
  return {
    type: actions.FETCH_BRANDS_FAIL,
    error: error
  }
}

export const fetchBrands = (token) => {
  return dispatch => {
    dispatch(fetchBrandsStart())
    axios.get('brand', {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    })
      .then(res => {
        dispatch(fetchBrandsSuccess(res.data))
      })
      .catch(err => {
        dispatch(fetchBrandsFail(err))
      })
  }
}

export const storeBrandStart = () => {
  return {
    type: actions.STORE_BRAND_START
  }
}

export const storeBrandSuccess = () => {
  return {
    type: actions.STORE_BRAND_SUCCESS,
  }
}

export const storeBrandFail = (error) => {
  return {
    type: actions.STORE_BRAND_FAIL,
    error: error
  }
}

export const storeBrand = (storeData, token) => {

  return dispatch => {
    dispatch(storeBrandStart())
    axios.post('brand', storeData, {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    })
      .then(response => {
        dispatch(storeBrandSuccess());
        dispatch(setAlert(response.data.msg_str, 'success'))
      })
      .catch(error => {
        dispatch(storeBrandFail(error.response.data.throwable))
        dispatch(setAlert(error.response.data.msg_str, 'error'))
      })
  }
}

export const updateBrandStart = () => {
  return {
    type: actions.UPDATE_BRAND_START
  }
}

export const updateBrandSuccess = () => {
  return {
    type: actions.UPDATE_BRAND_SUCCESS,
  }
}

export const updateBrandFail = (error) => {
  return {
    type: actions.UPDATE_BRAND_FAIL,
    error: error
  }
}

export const updateBrand = (id, storeData, token) => {

  return dispatch => {
    dispatch(updateBrandStart())
    axios.put('brand/'+ id, storeData, {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    })
      .then(response => {
        dispatch(updateBrandSuccess())
        dispatch(setAlert(response.data.msg_sys, 'success'))
      })
      .catch(error => {
        dispatch(updateBrandFail(error.response.data.throwable))
        dispatch(setAlert(error.response.data.msg_sys, 'success'))
      })
  }
}

export const clearErrorBrand = () => {
  return {
    type: actions.CLEAR_ERROR_BRAND
  }
}