import React, { Fragment, useEffect, useState } from 'react';
import {
  Button, Card, CardContent, CardMedia, Grid, IconButton, makeStyles, Paper, Table, CircularProgress as Loading,
  TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip, Typography, Fab, CardActionArea
} from '@material-ui/core';
import {Search} from '@material-ui/icons';
import palette from '../../../../theme/palette';
import { connect } from 'react-redux';
import * as actions from '../../../../store/actions/index';
import Modal from '../../../UI/Modal/ScrollDialog/ScrollDialog'
import NumberFormat from 'react-number-format';

import { useHistory, useParams } from 'react-router-dom'

import AddStatusForm from './Status/AddStatusForm';
import EditStatusForm from './Status/Form/EditStatusForm/EditStatusForm';
import Chart from './../../AsetWakaf/DetailAset/Chart/Chart'

import ListStatus from './Status/ListStatus';

import ListRemainder from './Remainder/ListReminder/ListReminder';
import AddReminder from './Remainder/AddRemainder/AddReminder';
import EditReminder from './Remainder/EditReminder/EditReminder';
import ListHistory from './History/ListHistory/ListHistory';
import AddHistory from './History/AddHistory/AddHistory';
import EditHistory from './History/EditHistory/EditHistory';

import moment from 'moment';

const useStyles = makeStyles(theme => ({
  row: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  contentSpace: {
    padding: '10px 32px'
    // backgroundColor: '#EEEEEE'
  },
  title: {
    fontSize: 14
  },
  fab: {
    position: 'fixed',
    bottom: theme.spacing(4),
    right: theme.spacing(2)
  },
  sideRight: {
    textAlign: 'right'
  },
  btnAdd: {
    backgroundColor: '#FF9300',
    '&:hover': {
      backgroundColor: '#FF9300'
    },
    color: '#FFFFFF',
    justifyContent: 'center',
    margin: '0px 5px'
  },
  btnAddStatus: {
    backgroundColor: palette.error.main,
    '&:hover': {
      backgroundColor: palette.error.dark,
    },
    color: '#FFFFFF',
    justifyContent: 'center',
    margin: '0px 5px'
  },
  btnAddReminder: {
    backgroundColor: palette.secondary.main,
    '&:hover': {
      backgroundColor: palette.secondary.dark,
    },
    color: '#FFFFFF',
    justifyContent: 'center',
    margin: '0px 5px'
  },
  extendedIcon: {
    marginRight: theme.spacing(1)
    // justifyContent: 'center'
  },
  searchRoot: {
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    width: 'auto'
  // marginTop: theme.spacing(2)
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1
  },
  iconButton: {
    padding: 10
  },
  divider: {
    height: 28,
    margin: 4
  },
  table: {
    minWidth: 500
  },
  topTable: {
    padding: 16,
    backgroundColor: '#FAFAFA'
  },
  formRoot: {
    // border: '1px groove #000',
    borderRadius: 8,
    boxShadow: theme.shadows[5],
    marginBottom: theme.spacing(2),
    padding: theme.spacing(2, 4, 3)
  },
  imageList: {
    maxWidth: 345,
  },
  media: {
    height: 140,
  },
  detailAset: {
    color: '#263238',
    fontSize: '14px',
    fontFamily: "Roboto, Helvetica, Arial, sans-serif",
    fontWeight: 500,
    lineHeight: '20px',
    letterSpacing: '-0.05px',
  }

}))

const Histories = props => {

  const historyPath = useHistory();
  let { uuid } = useParams();
  
  const { onFetchAsset, token, assetDetail, onSetAuthRedirect, change, onFetchAssetDevauling, assetDevaluing } = props;
  const path = historyPath.location.pathname;
  useEffect(() => {
    onSetAuthRedirect(path)
    onFetchAsset(uuid, token)
    onFetchAssetDevauling(uuid, token)
    closedModalDialog()
  }, [onFetchAsset, uuid, token, path, change, onSetAuthRedirect, onFetchAssetDevauling]);

  const classes = useStyles();

  let loading = null;
  if (props.loading) {
    loading = <Loading />;
  }

  let namaAset = null;
  let detailAset = null;
  let qr = null;
  let gambarAset = null;
  let dokumenAset = null;
  let tableHistory = '';
  let tableStatus = '';
  let tableRemainder = '';
  let chart = <Loading />;

  function isEmpty(obj) {
    for(var key in obj) {
        if(obj.hasOwnProperty(key))
            return false;
    }
    return true;
  }

  
  
  if (!isEmpty(assetDetail)) {
    let penyusutan = 0;
    let nilai_aset_saat_ini = 0;
    let current = moment(new Date()).format();
    if(!isEmpty(assetDevaluing)){
      if(current < moment(assetDevaluing[0].date).format()){
        penyusutan = 0;
        nilai_aset_saat_ini = assetDetail.value;
      }
      else if(current >= moment(assetDevaluing[assetDevaluing.length-1].date).format()){
        penyusutan = assetDetail.value;
        nilai_aset_saat_ini = 0;
      }
      else{
        for(var i=0; i < assetDevaluing.length; i++){
          if(current <= moment(assetDevaluing[i].date).format()){
            penyusutan = assetDevaluing[i-1].depreciation;
            nilai_aset_saat_ini = assetDevaluing[i-1].asset_value;
            break;
          }
        }
      }
    }

    namaAset = assetDetail.name;
    detailAset = (
      <Grid item lg={12} md={12} sm={12} xs={12} className={classes.detailAset}>
        <Grid container spacing={2}>
          <Grid item lg={4} md={4} sm={12} xs={12}>
            <Grid container spacing={2}>
              <Grid item lg={12} md={12} sm={12} xs={12} >
                <Grid container spacing={2}>
                  <Grid item lg={6} md={6} sm={6} xs={6} >
                    Jenis Asset
                  </Grid>
                  <Grid item lg={6} md={6} sm={6} xs={6} >
                    : {assetDetail.type ? assetDetail.type.name : '-'}
                  </Grid>  
                </Grid>
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item lg={12} md={12} sm={12} xs={12} >
                <Grid container spacing={2}>
                  <Grid item lg={6} md={6} sm={6} xs={6} >
                    Kategori Aset
                  </Grid>
                  <Grid item lg={6} md={6} sm={6} xs={6} >
                    : {assetDetail.category ? assetDetail.category.name : '-'}
                  </Grid>  
                </Grid>
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item lg={12} md={12} sm={12} xs={12} >
                <Grid container spacing={2}>
                  <Grid item lg={6} md={6} sm={6} xs={6} >
                    Tanggal Diterima
                  </Grid>
                  <Grid item lg={6} md={6} sm={6} xs={6} >
                    : {moment(assetDetail.received_date).subtract(new Date().getTimezoneOffset(), 'minutes').format('DD/MM/YYYY HH:mm:ss')}
                  </Grid>  
                </Grid>
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item lg={12} md={12} sm={12} xs={12} >
                <Grid container spacing={2}>
                  <Grid item lg={6} md={6} sm={6} xs={6} >
                    Pemilik Aset
                  </Grid>
                  <Grid item lg={6} md={6} sm={6} xs={6} >
                    : {assetDetail.wakif.length > 0 ? assetDetail.wakif[assetDetail.wakif.length - 1].name : '-'}
                  </Grid>  
                </Grid>
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item lg={12} md={12} sm={12} xs={12} >
                <Grid container spacing={2}>
                  <Grid item lg={6} md={6} sm={6} xs={6} >
                    Alamat
                  </Grid>
                  <Grid item lg={6} md={6} sm={6} xs={6} >
                    : {assetDetail.wakif.length > 0 ? assetDetail.wakif[assetDetail.wakif.length - 1].address : '-'}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item lg={12} md={12} sm={12} xs={12} >
                <Grid container spacing={2}>
                  <Grid item lg={6} md={6} sm={6} xs={6} >
                    Nomor Telepon
                  </Grid>
                  <Grid item lg={6} md={6} sm={6} xs={6} >
                    : {assetDetail.wakif.length > 0 ? assetDetail.wakif[assetDetail.wakif.length - 1].phone : '-'}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item lg={3} md={3} sm={3} xs={3} />
          <Grid item lg={4} md={4} sm={12} xs={12}>
            <Grid container spacing={2}>
              <Grid item lg={12} md={12} sm={12} xs={12} >
                <Grid container spacing={2}>
                  <Grid item lg={6} md={6} sm={6} xs={6} >
                    Nilai Perolehan
                  </Grid>
                  <Grid item lg={6} md={6} sm={6} xs={6} >
                    : <NumberFormat thousandSeparator={'.'} prefix={'Rp '} decimalSeparator={','} displayType={'text'} value={assetDetail.value}/>
                  </Grid>  
                </Grid>
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item lg={12} md={12} sm={12} xs={12} >
                <Grid container spacing={2}>
                  <Grid item lg={6} md={6} sm={6} xs={6} >
                    Nilai Aset Saat Ini
                  </Grid>
                  <Grid item lg={6} md={6} sm={6} xs={6} >
                    : <NumberFormat thousandSeparator={'.'} prefix={'Rp '} decimalSeparator={','} displayType={'text'} value={nilai_aset_saat_ini}/>
                  </Grid>  
                </Grid>
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item lg={12} md={12} sm={12} xs={12} >
                <Grid container spacing={2}>
                  <Grid item lg={6} md={6} sm={6} xs={6} >
                    Nilai Penyusutan
                  </Grid>
                  <Grid item lg={6} md={6} sm={6} xs={6} >
                    : <NumberFormat thousandSeparator={'.'} prefix={'Rp '} decimalSeparator={','} displayType={'text'} value={penyusutan}/>
                  </Grid>  
                </Grid>
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item lg={12} md={12} sm={12} xs={12} >
                <Grid container spacing={2}>
                  <Grid item lg={6} md={6} sm={6} xs={6} >
                    Tahun Penyusutan
                  </Grid>
                  <Grid item lg={6} md={6} sm={6} xs={6} >
                    : {assetDetail.year} Tahun
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item lg={12} md={12} sm={12} xs={12} >
                <Grid container spacing={2}>
                  <Grid item lg={6} md={6} sm={6} xs={6} >
                    Deskripsi
                  </Grid>
                  <Grid item lg={6} md={6} sm={6} xs={6} >
                    : {assetDetail.description}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid> 
      </Grid>
    )

    qr = (
      // <QRCode value={assetDetail.uuid}/>
      <img src={assetDetail.qr_link} alt="qr_eoa_club" width="60%" height="auto"/>
    )
    
    if(assetDetail.image.length > 0){
      gambarAset = (
        <Fragment>
          <Grid
            container
            spacing={2}
          >
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Card className={classes.root}>
                <CardContent>
                  {/* <PerfectScrollbar> */}
                    <Grid
                      container
                      spacing={2}
                    >
                    {assetDetail.image.map(gambar => (
                      <Fragment key={gambar.uuid}>
                        <Grid item lg={3} md={3} sm={12} xs={12} >
                          <Card className={classes.imageList}>
                             <CardActionArea onClick={() => previewImage(gambar)}>
                              <CardMedia
                                className={classes.media}
                                image={gambar.url}
                                title="Contemplative Reptile"
                              />
                             </CardActionArea>
                            <CardContent>
                              <center>
                                <Typography variant="h5" style={{overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap"}}>
                                  {gambar.caption}
                                </Typography>
                              </center>
                              
                            </CardContent>
                            
                          </Card>
                        </Grid>
                      </Fragment>
                    ))}
                    </Grid>
                  {/* </PerfectScrollbar> */}
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Fragment>
      )
    }

    if(assetDetail.document.length > 0){
      dokumenAset = ( 
      <Fragment>
        <Grid
          container
          spacing={2}
        >
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Card className={classes.root}>
              <CardContent>
                <Grid
                  container
                  spacing={2}
                >
                  
                    <TableContainer component={Paper}>
                      <Table aria-label="customized table">
                        <TableHead>
                          <TableRow>
                            <TableCell style={{ width: '5%' }}>No</TableCell>
                            <TableCell style={{ width: '90%' }}>Nama Dokumen</TableCell>
                            <TableCell style={{ width: '5%' }}>Action</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {assetDetail.document.map((document, index) => (
                            <TableRow key={index}>
                              <TableCell style={{ width: '5%' }}>{index+1}</TableCell>
                              <TableCell style={{ width: '90%' }}>{document.caption}</TableCell>
                              <TableCell style={{ width: '5%' }}>
                                <Tooltip title="Detail Document">
                                  <a href={document.url} target="blank">
                                    <IconButton aria-label="detail">
                                      <Search />
                                    </IconButton>
                                  </a>  
                                </Tooltip>
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Fragment>)
    }

    tableHistory = (
      <ListHistory 
        assetDetail={assetDetail}
        addHistory={() => add(props.assetDetail)}
        editHistory={(history) => editHistory(history)}
      />
    )

    tableStatus = (
      <ListStatus 
        assetDetail={assetDetail}
        addStatusForm={() => addStatusForm(props.assetDetail)}
        editStatusForm={(status) => editStatusForm(status)}
      />
    )

    tableRemainder = (
      <ListRemainder 
        assetDetail={assetDetail}
        addReminderForm={() => addReminderForm(props.assetDetail)}
        editReminderForm={(reminder) => editReminderForm(reminder)}
      />
    )

    if(!isEmpty(assetDevaluing)){
      chart = (
        <Chart assetDevaluing={assetDevaluing} />
      )
    }
  }

  const [form, setForm] = useState('');
  
  const add = (asset) => {
    setForm(<AddHistory assetDetail={assetDetail}  closedModalDialog={() => closedModalDialog()} />);
    setModalState({
      open: true,
      title: asset.name,
      maxWidth: 'md'
    });
  }

  const editHistory = (history) => {
    setForm(<EditHistory  assetDetail={assetDetail} history={history} closedModalDialog={() => closedModalDialog()} />)
    setModalState({
      open: true,
      title: assetDetail.name,
      maxWidth: 'md'
    });
  }

  const addStatusForm = asset => {
    setForm(<AddStatusForm assetDetail={assetDetail} closedModalDialog={() => closedModalDialog()} />)
    setModalState({
      open: true,
      title: assetDetail.name,
      maxWidth: 'sm'
    });
    console.log(asset)
  }

  const editStatusForm = (status) => {
    setForm(<EditStatusForm assetDetail={assetDetail} statusAset={status} closedModalDialog={() => closedModalDialog()} />);
    setModalState({
      open: true,
      title: assetDetail.name,
      maxWidth: 'sm'
    });
  }

  const addReminderForm = asset => {
    setForm(<AddReminder assetDetail={assetDetail} closedModalDialog={() => closedModalDialog()} />);
    setModalState({
      open: true,
      title: assetDetail.name,
      maxWidth: 'md'
    });
  }

  const editReminderForm = (reminder) => {
    setForm(<EditReminder assetDetail={assetDetail} reminderAset={reminder} closedModalDialog={() => closedModalDialog()} />);
    setModalState({
      open: true,
      title: assetDetail.name,
      maxWidth: 'md'
    });
  }

  const previewImage = (gambar) => {
    // console.log(image)
    setForm(
      <Grid container spacing={2}>
        <Grid item lg={12} md={12} sm={12} xs={12} >
          <img src={gambar.url} alt="image preview" aria-hidden="true" title="image preview" width={'100%'} height={"auto"} />
          {/*<Card className={classes.imageList}>*/}
          {/*    <CardMedia*/}
          {/*      className={classes.media}*/}
          {/*      image={gambar.url}*/}
          {/*      title="Contemplative Reptile"*/}
          {/*    />*/}
          {/*  <CardContent>*/}
          {/*    <center>*/}
          {/*      <Typography variant="h5" style={{overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap"}}>*/}
          {/*        {gambar.caption}*/}
          {/*      </Typography>*/}
          {/*    </center>*/}

          {/*  </CardContent>*/}

          {/*</Card>*/}
        </Grid>
      </Grid>
    );
    setModalState({
      open: true,
      title: gambar.caption,
      maxWidth: 'xs'
    });
  }

  const closedModalDialog = () => {
    setModalState({
      open: false,
      title: '',
      maxWidth: 'xs'
    });
  }
  
  const [modalState, setModalState] = useState({
    open: false,
    title: '',
    maxWidth: 'sm',
  });

  const pathname = window.location.pathname;

  return (
    <Fragment>
      <div className={classes.contentSpace}>
        {loading}
        
        <Modal
          maxWidth={modalState.maxWidth}
          open={modalState.open}
          title={modalState.title}
          onCloseModal={closedModalDialog}
          contentModal={form}
        />

        <div className={classes.row}>
          <Grid container justify="space-between">
            <Grid item>
              <Typography variant="h4">
                {namaAset}
              </Typography>
            </Grid>
            <Grid>
              <Grid container>
                <Grid item>
                  <Fab variant="extended" className={classes.btnAddReminder} href={pathname+'#reminder'}>
                    Reminder
                  </Fab>
                </Grid>
                <Grid item>
                  <Fab variant="extended" className={classes.btnAddStatus} href={pathname+'#status'}>
                    Status
                  </Fab>
                </Grid>
                <Grid item>
                  <Fab variant="extended" className={classes.btnAdd} href={pathname+'#penerima'}>
                    Pengguna
                  </Fab>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </div>

        <div className={classes.row}>
          <Grid
            container
            spacing={2}
            justify='space-between'
          >
            <Grid item lg={12} md={12} sm={12} xs={12} >
              <Card className={classes.root}>
                <CardContent>
                  {chart}

                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </div>

        <div className={classes.row}>
          <Grid
            container
            spacing={2}
            justify='space-between'
          >
            <Grid item lg={12} md={12} sm={12} xs={12} >
              <Card className={classes.root}>
                <CardContent>
                  {detailAset}

                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </div>

        <div className={classes.row}>
          <Grid
            container
            spacing={2}
            justify='space-between'
          >
            <Grid item lg={6} md={6} sm={12} xs={12} >
              
                <Grid
                  container
                  spacing={2}
                >
                  {/* <PerfectScrollbar> */}
                  <Grid item lg={12} md={12} sm={12} xs={12}>                  
                    {gambarAset}                   
                  </Grid>
                  {/* </PerfectScrollbar> */}
                  
                </Grid>
                
              <Grid
                container
                spacing={2}
              >
                <Grid item lg={12} md={12} sm={12} xs={12}>                 
                  {dokumenAset}                  
                </Grid>
              </Grid>
            </Grid>
            <Grid item lg={2} md={2} sm={2} xs={2} />
            <Grid item lg={4} md={4} sm={12} xs={12} >
              <Card className={classes.root}>
                <CardContent>
                  <div>
                    <center>
                      {qr}
                    </center>
                    
                  </div>
                  <div><Button variant="contained"  fullWidth className={classes.btnAdd} href={assetDetail.qr_link} target="blank">QR CODE</Button></div>
                    
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </div>

        <hr />
        <div className={classes.row} id="penerima">
          <Grid container spacing={2}>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              {tableHistory}
            </Grid>
          </Grid>
        </div>

        <hr />

        <div className={classes.row} id="status">
          <Grid container spacing={2}>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              {tableStatus}
            </Grid>
          </Grid>
        </div>

        <div className={classes.row} id="reminder">
          <Grid container spacing={2}>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              {tableRemainder}
            </Grid>
          </Grid>
        </div>

      </div>
    </Fragment>
  )
}

const mapStateToProps = state => {
  return {
    token: state.auth.token,
    assetDetail: state.assetDetail.assets,
    loading: state.assetDetail.loading,
    change: state.assetDetail.changing,
    assetDevaluing: state.asset.assetDevaluing,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onSetAuthRedirect: (path) => dispatch(actions.setAuthRedirectPath(path)),
    onFetchAsset: (uuid, token) => dispatch(actions.fetchAssetDetails(uuid, token)),
    onFetchAssetDevauling: (uuid, token) => dispatch(actions.fetchAssetDepreciation(uuid, token)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps) (Histories);
