import React,{Fragment} from "react";
import {
  Button,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead, TablePagination,
  TableRow,
  Typography
} from "@material-ui/core";
import {isEmpty} from '../../../shared/utility';
import TablePaginationActions from "../../UI/Table/TablePagination/TablePaginationActions";
import Report from "./Report/Report";
import {RefreshOutlined} from "@material-ui/icons";

const columns = [
  { id: 'no', label: 'No', minWidth:'5%'  },
  { id: 'nama_dokumen', label: 'Nama Dokumen', minWidth:'55%'  },
  { id: 'tanggal_generate', label: 'Tanggal Generate',minWidth:'25%' },
  { id: 'status', label: 'Status', minWidth:'10%' },
  { id: 'download', label: 'Download', minWidth:'5%'},
]

const Reports = props => {
  const {reports, classes,propsHandleChangePage, refresh} = props;

  let tableBody = '';

  const handleChangePage = (event, newPage) => {
    propsHandleChangePage(newPage);
  }

  if(!isEmpty(reports)){
    const page = reports.current_page - 1
    const rowsPerPage = reports.per_page
    const countRows = reports.total

    tableBody = (
      <Fragment>
        <TableBody>
          {reports.data.map((report,index) => (
            <Report
              report={report}
              key={report.id_report}
              classes={classes}
              index={index}
              from={reports.from}
              // detailed={() => props.show(asset)}
              // edited={() => props.edit(asset)}
              // deleted={() => {
              //   props.onCloseAlert()
              //   props.onDialogBox('Yakin ingin menghapus data Aset? ', asset, asset.uuid, actions.deleteAsetWakaf)
              // }}
            />
          ))}
        </TableBody>
        <TableFooter>
          <TableRow>
            <TablePagination
              rowsPerPageOptions={[reports.per_page]}
              colSpan={10}
              count={countRows}
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={handleChangePage}
              ActionsComponent={TablePaginationActions}
            />
          </TableRow>
        </TableFooter>
      </Fragment>
    )
  }

  return (
    <Fragment>
      <Paper>
        <div className={classes.paperPadding}>
          <div className={classes.paperTag}>
            <Grid container spacing={2} justify={"space-between"}>
              <Grid item>
                <Typography variant={"h5"}>
                  <b>List Download</b>
                </Typography>
              </Grid>
              <Grid item>
                <Button variant={"contained"} className={classes.btnGenerate} size={"small"} startIcon={<RefreshOutlined />} onClick={refresh}>
                  Refresh
                </Button>
              </Grid>
            </Grid>
          </div>
          <div>
            <TableContainer>
              <Table className={classes.table} aria-label="custom pagination table">
                <TableHead>
                  <TableRow>
                    {columns.map((column) => (
                      <TableCell
                        key={column.id}
                        align={column.align}
                        style={{ minWidth: column.minWidth }}
                      >
                        {column.label}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                {tableBody}
              </Table>
            </TableContainer>
          </div>
        </div>
      </Paper>
    </Fragment>
  );
};

export default Reports;