import React from 'react';
import { IconButton, TableCell, TableRow, Tooltip } from '@material-ui/core';
import { Edit as EditIcon } from '@material-ui/icons';

const Entity = props => {
  const {entity} = props;

  return (
    <TableRow key={entity.id_entity}>
      <TableCell>
        {entity.name}
      </TableCell>
      <TableCell>
        {entity.description}
      </TableCell>
      <TableCell style={{ width: 150 }} align="right">
        <Tooltip title="Edit Entitas">
          <IconButton aria-label="edit" onClick={props.edited}>
            <EditIcon />
          </IconButton>
        </Tooltip>
      </TableCell>
    </TableRow>
  )
}

export default Entity;