import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../../../../store/actions/index';

import { Grid, Paper, Typography, makeStyles, TextField, Button, CircularProgress as Loading } from '@material-ui/core';
import * as yup from 'yup'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers'

const useStyles = makeStyles(theme => ({
  row: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  formRoot: {
    borderRadius: 8,
    boxShadow: theme.shadows[5],
    marginBottom: theme.spacing(2),
    padding: theme.spacing(2, 4, 3)
  }
}));

const schema = yup.object().shape({
  // status: yup.string().required(),
  // description: yup.string().required()
});

const AddBrand = props => {
  const classes = useStyles();
  const { onStoreBrand, loading, error, token, onClearError } = props;

  useEffect(() => {
    onClearError()
  },[onClearError])

  const { register, handleSubmit } = useForm({
    resolver: yupResolver(schema)
  })

  const onSubmit = data => {
    onStoreBrand(data, token);
    // console.log(data);
  }

  const handleChange = (event) => {
    const target = event.target.name
    event.persist()

    if(event.target.type === 'checkbox'){
      setFormState((formState) => ({
        ...formState,
        [target]: {
          value: event.target.checked
        }
      }))
    }else{
      setFormState((formState) => ({
        ...formState,
        [target]: {
          value: event.target.value
        }
      }))
    }
  }

  const [formState, setFormState] = useState({
    name: '',
    description: '',
  });

  let button = (loading ? <Loading /> : (
    <Button type="submit" variant="contained" style={{ backgroundColor: '#FF9300', color: '#FFFFFF' }} fullWidth disableElevation>
      Simpan
    </Button>
  ))

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={2}>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Paper className={classes.formRoot}>
            <Typography>Merek</Typography>
            <div className={classes.row}>
              <Grid container spacing={2}>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <TextField
                    label="Nama Merek"
                    variant="outlined"
                    name="name"
                    fullWidth
                    inputRef={register}
                    error={error.name ? true : false}
                    helperText={error.name && error.name[0]}

                    defaultValue={formState.name}
                    onChange={handleChange}
                  />
                </Grid>
              </Grid>
            </div>
          </Paper>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item lg={8} md={8} sm={8} xs={8} />
        <Grid item lg={4} md={4} sm={4} xs={4} >
          <Grid container spacing={2}>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Grid container spacing={2}>
                <Grid item lg={6} md={6} sm={12} xs={12}>
                  <Button variant="contained" fullWidth disableElevation onClick={props.closedModalDialog}>
                    Batal
                  </Button>
                </Grid>
                <Grid item lg={6} md={6} sm={12} xs={12}>
                  {button}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

      </Grid>
    </form>
  )
}

const mapStateToProps = state => {
  return{
    token: state.auth.token,
    loading: state.brand.loading,
    error: state.brand.error,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onStoreBrand:(data, token) => dispatch(actions.storeBrand(data, token)),
    onClearError:() => dispatch(actions.clearErrorBrand())
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AddBrand);