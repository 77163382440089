import React, { Fragment, useEffect } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../../../../store/actions/index';
import { Paper, Table, TableCell, TableContainer, TableHead, TableRow, Typography, makeStyles, TableBody } from '@material-ui/core';
import Entity from './Entity/Entity';
// import TablePaginationActions from '../../../../UI/Table/TablePagination/TablePaginationActions';

const useStyle = makeStyles(theme => ({
  table: {
    minWidth: 500
  },
  topTable: {
    padding: 16,
    backgroundColor: '#FAFAFA'
  }
}))

const columns = [
  // { id: 'no', label: 'No' },
  { id: 'nama', label: 'Nama Entitas' },
  { id: 'deskripsi', label: 'Deskripsi' },
  { id: 'action', label: 'Action', align:"right"}
]

const EntityList = props => {

  const {entities, changing, token, onFetchEntities} = props;
  const classes = useStyle();

  function isEmpty(obj) {
    for(var key in obj) {
        if(obj.hasOwnProperty(key))
            return false;
    }
    return true;
  }

  // const handleChangePage = (event, newPage) => {
  //   onFetchEntities(newPage, token)
  // }

  let tableBody = '';
  if(!isEmpty(entities)){
    // const page = entities.current_page - 1
    // const rowsPerPage = entities.per_page
    // const countRows = entities.total
    // const emptyRows = rowsPerPage - Math.min(rowsPerPage, countRows - page * rowsPerPage)

    tableBody = (   
      <Fragment>
        <TableBody>
          {entities.map((entity, index) => (
            <Entity
              entity={entity}
              key={entity.id_entity}
              index={index}
              edited={() => props.editEntity(entity)}
            />
          )).reverse()
          }
          {/* {emptyRows > 0 && (
            <TableRow style={{ height: 53 * emptyRows }}>
              <TableCell colSpan={8} />
            </TableRow>
          )} */}
        </TableBody>
        {/* <TableFooter>
          <TableRow>
            <TablePagination
              rowsPerPageOptions={[entities.per_page]}
              colSpan={4}
              count={countRows}
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={handleChangePage}
              ActionsComponent={TablePaginationActions}
            />
          </TableRow>
        </TableFooter> */}
      </Fragment>
    )
  }

  useEffect(() => {
    onFetchEntities(token)
  },[onFetchEntities, token, changing])

  return (
    <TableContainer component={Paper}>
      <div className={classes.topTable}>
        <Typography variant="h4">List Entitas</Typography>
      </div>
      <hr />
      <Table className={classes.table} aria-label="custom pagination table">
        <TableHead>
          <TableRow>
            {columns.map((column) => (
              <TableCell
                key={column.id}
                align={column.align}
                style={{ minWidth: column.minWidth }}
              >
                {column.label}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        {tableBody}
      </Table>
    </TableContainer>
  )
}

const mapStateToProps = state => {
  return {
    entities: state.entity.entities,
    loading: state.entity.loading,
    token: state.auth.token,
    changing: state.entity.changing,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onFetchEntities: (token) => dispatch(actions.fetchEntity(token))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(EntityList);