import React, {Fragment} from "react";
import {IconButton, TableCell, TableRow, Tooltip} from "@material-ui/core";
import {Edit as EditIcon} from "@material-ui/icons";

const Brand = props => {
  const {brand} = props;
  const status = (brand.is_active === 1 ? 'Aktif' : 'Tidak Aktif');

  return (
    <Fragment>
      <TableRow key={brand.id_brand}>
        <TableCell>
          {brand.name}
        </TableCell>
        <TableCell>
          {status}
        </TableCell>
        <TableCell style={{ width: 150 }} align="right">
          <Tooltip title="Edit Merek">
            <IconButton aria-label="edit" onClick={props.edited}>
              <EditIcon />
            </IconButton>
          </Tooltip>
        </TableCell>
      </TableRow>
    </Fragment>
  )
}

export default Brand;