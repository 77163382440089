import React, { Fragment, useEffect, useState } from 'react';
import { Grid, Paper, Typography, makeStyles, TextField, Button, CircularProgress as Loading, FormControl, InputLabel, Select, FormHelperText } from '@material-ui/core';

import * as yup from 'yup'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers'

import * as actions from '../../../../../../store/actions/index';
import { connect } from 'react-redux';
import moment from 'moment'
import { DatePicker } from '@material-ui/pickers';

const useStyles = makeStyles(theme => ({
  row: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  formRoot: {
    borderRadius: 8,
    boxShadow: theme.shadows[5],
    marginBottom: theme.spacing(2),
    padding: theme.spacing(2, 4, 3)
  }
}));

const schema = yup.object().shape({
});

const EditHistory = props => {
  const classes = useStyles();
  const { onGiveAssetUpdate, loading, error, token, onClearError, assetDetail, history, entities, divisions, onFetchEntity, onFetchDivision } = props;
  // console.log(assetDetail);
  useEffect(() => {
    onFetchEntity(token)
    onFetchDivision(token)
    onClearError()
  },[onClearError, token, onFetchEntity,onFetchDivision])

  const { register, handleSubmit, errors } = useForm({
    resolver: yupResolver(schema)
  })
  
  const onSubmit = data => {
    const jam_utc = moment.utc().format('HH:mm:ss');
    let dataEdit = {};
    dataEdit.return_date = moment.utc(moment(returnDate).format('YYYY-MM-DD')+' '+jam_utc).format('YYYY-MM-DD HH:mm:ss');
    // dataEdit.return_date = moment.utc(moment(returnDate).toDate()).format('YYYY-MM-DD HH:mm:ss');
    dataEdit.emp_id = '0';
    onGiveAssetUpdate(data.id_history, dataEdit, token);
  }
  
  const handleChange = (event) => {
    const target = event.target.name
    event.persist()
    
    if(event.target.type === 'checkbox'){
      setFormState((formState) => ({
        ...formState,
        [target]: {
          value: event.target.checked
        }
      }))
    }else{
      setFormState((formState) => ({
        ...formState,
        [target]: {
          value: event.target.value
        }
      }))
    }
  }
  
  const [formState, setFormState] = useState({
    id_history: {
      value: history.id_history
    },
    uuid: {
      value: assetDetail.uuid
    },
    emp_id: {
      value: history.emp_id
    },
    name: {
      value: history.name
    },
    location: {
      value: history.location
    },
    notes: {
      value: history.notes
    },
    return_date: {
      value: moment.utc(history.return_date).subtract(new Date().getTimezoneOffset(), 'minutes').format('YYYY-MM-DD')
    },
    id_entity: {
      value: history.id_entity
    },
    id_division: {
      value: history.id_division
    }
  });

  function isEmpty(obj) {
    for(var key in obj) {
        if(obj.hasOwnProperty(key))
            return false;
    }
    return true;
  }

  let optionEntity = '';
  if (!isEmpty(entities)) {
    optionEntity = (
      <Fragment>
        {entities.map((entity) => (        
          <option key={entity.id_entity} value={entity.id_entity}>{entity.name}</option>
        ))}
      </Fragment>
    )
  }

  let optionDivision = '';
  if (!isEmpty(divisions)) {
    optionDivision = (
      <Fragment>
        {divisions.map((division) => (
          <option key={division.id_division} value={division.id_division}>{division.name}</option>
        ))}
      </Fragment>
    )
  }

  let button = (loading ? <Loading /> : (
    <Button type="submit" variant="contained" style={{ backgroundColor: '#FF9300', color: '#FFFFFF' }} fullWidth disableElevation>
      Simpan
    </Button>
  ))

  // const [returnDate, setReturnDate] = useState(moment(moment(history.return_date).toDate()).subtract(new Date().getTimezoneOffset(), 'minutes'));
  let date = moment(history.return_date).subtract(new Date().getTimezoneOffset(), 'minutes').format('DD/MM/YYYY HH:mm:ss')
  const [returnDate, setReturnDate] = useState(moment(date).toDate());
  const handleDateChange = event => {
    setReturnDate(moment(event));
  }

  // console.log(returnDate)

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <TextField
        variant="standard"
        name="uuid"
        inputRef={register}
        error={!!errors.uuid}
        helperText={errors.uuid && errors.uuid.message}
        defaultValue={formState.uuid.value}
        type="hidden"
        onChange={handleChange}
      />
      <TextField
        variant="standard"
        name="id_history"
        inputRef={register}
        error={!!errors.id_history}
        helperText={errors.id_history && errors.id_history.message}
        defaultValue={formState.id_history.value}
        type="hidden"
        onChange={handleChange}
      />
      <Grid container spacing={2}>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Paper className={classes.formRoot}>
            <div className={classes.row}>
              <Grid container spacing={2}>
                <Grid item lg={6} md={6} sm={12} xs={12}>
                  <Typography>Penggunaan Aset</Typography>
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item lg={6} md={6} sm={12} xs={12}>
                  <TextField
                    label="Nama Penerima"
                    variant="outlined"
                    name="name"
                    fullWidth
                    inputRef={register}
                    error={!!errors.name}
                    helperText={errors.name && errors.name.message}
                    defaultValue={formState.name.value}
                    onChange={handleChange}
                    disabled={true}
                  />
                </Grid>
                <Grid item lg={6} md={6} sm={12} xs={12}>
                  <FormControl
                    error={error.id_entity ? true : false}
                    variant="outlined" className={classes.formControl} fullWidth>
                    <InputLabel htmlFor="outlined-age-native-simple">Entitas</InputLabel>
                    <Select
                      native
                      defaultValue={formState.id_entity.value}
                      onChange={handleChange}
                      label="Entitas"
                      inputProps={{
                        name: 'id_entity',
                        id: 'outlined-age-native-simple'
                      }}
                      name="id_entity"
                      inputRef={register}
                      disabled={true}
                    >
                      <option aria-label="None" value="" />
                      {optionEntity}
                    </Select>
                    <FormHelperText>{error.id_entity && error.id_entity[0]}</FormHelperText>
                  </FormControl>
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item lg={6} md={6} sm={12} xs={12}>
                  <FormControl
                    error={error.id_division ? true : false}
                    variant="outlined" className={classes.formControl} fullWidth>
                    <InputLabel htmlFor="outlined-age-native-simple">Divisi</InputLabel>
                    <Select
                      native
                      defaultValue={formState.id_division.value}
                      onChange={handleChange}
                      label="Divisi"
                      inputProps={{
                        name: 'id_division',
                        id: 'outlined-age-native-simple'
                      }}
                      name="id_division"
                      inputRef={register}
                      disabled={true}
                    >
                      <option aria-label="None" value="" />
                      {optionDivision}
                    </Select>
                    <FormHelperText>{error.id_division && error.id_division[0]}</FormHelperText>
                  </FormControl>
                </Grid>
                {/* <Grid item lg={6} md={6} sm={12} xs={12}> */}
                  {/* <TextField
                    label="No Karyawan"
                    variant="outlined"
                    name="emp_id"
                    fullWidth
                    inputRef={register}
                    error={!!errors.emp_id}
                    helperText={errors.emp_id && errors.emp_id.message}
                    disabled={true}
                    type="hidden"
                    defaultValue={formState.emp_id.value}
                    onChange={handleChange}
                  /> */}
                {/* </Grid> */}
              </Grid>
            </div>
            <div className={classes.row}>
              <Grid container spacing={2}>
                <Grid item lg={6} md={6} sm={12} xs={12}>
                  {/* <TextField
                    label="Tanggal Pengembalian Aset"
                    variant="outlined"
                    type="date"
                    name="return_date"
                    inputRef={register}
                    error={error.return_date ? true : false}
                    helperText={error.return_date && error.return_date[0]}
                    fullWidth
                    defaultValue={formState.return_date.value}
                    onChange={handleChange}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  /> */}
                  <DatePicker
                    variant="dialog"
                    format="DD/MM/yyyy"
                    views={["year", "month", "date"]}
                    label="Tanggal Pengembalian Aset"
                    error={error.return_date ? true : false}
                    helperText={error.return_date && error.return_date[0]}
                    value={returnDate}
                    onChange={handleDateChange}
                    fullWidth
                    name="return_date"
                    inputRef={register}
                  />
                </Grid>
              </Grid>
            </div>
            <div className={classes.row}>
              <Grid container spacing={2}>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <TextField
                    label="Keterangan"
                    variant="outlined"
                    name="notes"
                    fullWidth
                    inputRef={register}
                    error={!!errors.notes}
                    helperText={errors.notes && errors.notes.message}
                    disabled={true}
                    defaultValue={formState.notes.value}
                    onChange={handleChange}
                  />
                </Grid>
              </Grid>
            </div>
            <div className={classes.row}>
              <Grid container spacing={2}>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <TextField
                    label="Lokasi"
                    variant="outlined"
                    name="location"
                    fullWidth
                    multiline
                    rows={4}
                    inputRef={register}
                    error={!!errors.location}
                    helperText={errors.location && errors.location.message}
                    disabled={true}
                    defaultValue={formState.location.value}
                    onChange={handleChange}
                  />
                </Grid>
              </Grid>
            </div>
          </Paper>
        </Grid>
      </Grid>

      <Grid container spacing={2}>
        <Grid item lg={8} md={8} sm={12} xs={12}>
        </Grid>
        <Grid item lg={4} md={4} sm={12} xs={12}>
          <Grid container spacing={2}>
            <Grid item lg={6} md={6} sm={12} xs={12}>
              <Button variant="contained" fullWidth onClick={props.closedModalDialog} disableElevation>
                Batal
              </Button>
            </Grid>
            <Grid item lg={6} md={6} sm={12} xs={12}>
              {button}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </form>
  );
};

const mapStateToProps = state => {
  return {
    loading: state.assetDetail.loading,
    error: state.assetDetail.error,
    token: state.auth.token,
    entities: state.entity.entities,
    divisions: state.division.divisions
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onFetchEntity: (token) => dispatch(actions.fetchEntity(token)),
    onFetchDivision: (token) => dispatch(actions.fetchDivision(token)),
    onClearError: () => dispatch(actions.clearError()),
    onGiveAssetUpdate: (id, storeData, token) => dispatch(actions.updateGiveAsset(id, storeData, token)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(EditHistory);