import React, {Fragment, useEffect} from "react";
import {connect} from "react-redux";
import * as actions from '../../../../../store/actions';
import {
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from "@material-ui/core";
import {isEmpty} from "../../../../../shared/utility";
import Division from "./Divison/Division";

const useStyle = makeStyles(theme => ({
  table: {
    minWidth: 500
  },
  topTable: {
    padding: 16,
    backgroundColor: '#FAFAFA'
  }
}))

const columns = [
  // { id: 'no', label: 'No' },
  { id: 'nama', label: 'Nama Divisi' },
  { id: 'deskripsi', label: 'Deskripsi' },
  { id: 'action', label: 'Action', align:"right"}
]

const DivisionList = props => {

  const {
    divisions,
    onFetchDivision,
    token,
    changing
  } = props;

  useEffect(() => {
    onFetchDivision(token)
  },[onFetchDivision, token,changing])

  const classes = useStyle();

  console.log(divisions)
  let tableBody = '';
  if(!isEmpty(divisions)){
    // const page = entities.current_page - 1
    // const rowsPerPage = entities.per_page
    // const countRows = entities.total
    // const emptyRows = rowsPerPage - Math.min(rowsPerPage, countRows - page * rowsPerPage)

    tableBody = (
      <Fragment>
        <TableBody>
          {divisions.map((division, index) => (
            <Division
              division={division}
              key={division.id_division}
              index={index}
              edited={() => props.editDivisi(division)}
            />
          )).reverse()
          }
          {/* {emptyRows > 0 && (
            <TableRow style={{ height: 53 * emptyRows }}>
              <TableCell colSpan={8} />
            </TableRow>
          )} */}
        </TableBody>
        {/* <TableFooter>
          <TableRow>
            <TablePagination
              rowsPerPageOptions={[entities.per_page]}
              colSpan={4}
              count={countRows}
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={handleChangePage}
              ActionsComponent={TablePaginationActions}
            />
          </TableRow>
        </TableFooter> */}
      </Fragment>
    )
  }

  return (
    <Fragment>
      <TableContainer component={Paper}>
        <div className={classes.topTable}>
          <Typography variant="h4">List Divisi</Typography>
        </div>
        <hr />
        <Table className={classes.table} aria-label="custom pagination table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          {tableBody}
        </Table>
      </TableContainer>
    </Fragment>
  );
};

const mapStateToProps = state => {
  return {
    divisions: state.division.divisions,
    token: state.auth.token,
    changing: state.division.changing
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onFetchDivision: (token) => dispatch(actions.fetchDivision(token))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(DivisionList);