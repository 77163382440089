import * as actionTypes from '../actions/actionTypes'
import { updateObject } from '../../shared/utility'

const initialState = {
  brands: {},
  changing: 0,

  loading: false,
  error: {},
}

const fetchBrandsStart = (state, action) => {
  return updateObject(state, { loading: true })
}

const fetchBrandsSuccess = (state, action) => {
  return updateObject(state, {
    brands: action.brands,
    loading: false
  })
}

const fetchBrandsFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false
  })
}

const storeBrandStart = (state, action) => {
  return updateObject(state, { loading: true })
}

const storeBrandSuccess = (state, action) => {
  return updateObject(state, {
    changing: state.changing+1,
    loading: false
  })
}

const storeBrandFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false
  })
}

const updateBrandStart = (state, action) => {
  return updateObject(state, { loading: true })
}

const updateBrandSuccess = (state, action) => {
  return updateObject(state, {
    changing: state.changing+1,
    loading: false
  })
}

const updateBrandFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false
  })
}

const clearErrorBrand = (state, action) => {
  return updateObject(state, {
    error: {},
  })
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_BRANDS_START: return fetchBrandsStart(state, action)
    case actionTypes.FETCH_BRANDS_SUCCESS: return fetchBrandsSuccess(state, action)
    case actionTypes.FETCH_BRANDS_FAIL: return fetchBrandsFail(state, action)
    case actionTypes.STORE_BRAND_START: return storeBrandStart(state, action)
    case actionTypes.STORE_BRAND_SUCCESS: return storeBrandSuccess(state, action)
    case actionTypes.STORE_BRAND_FAIL: return storeBrandFail(state, action)
    case actionTypes.UPDATE_BRAND_START: return updateBrandStart(state, action)
    case actionTypes.UPDATE_BRAND_SUCCESS: return updateBrandSuccess(state, action)
    case actionTypes.UPDATE_BRAND_FAIL: return updateBrandFail(state, action)
    case actionTypes.CLEAR_ERROR_BRAND: return clearErrorBrand(state, action)

    default: return state
  }
}

export default reducer